import React, { useEffect, useState, useRef } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Fragment } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Empty, Spin, Alert, Table, Input } from 'antd';
import CustomerContactDetailsSlider from './CustomerContactDetailsSlider';
import SendEmailSlider from '../Email/SendEmailSlider';
import { useForm, Controller } from "react-hook-form";
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

export default function CustomerContacts(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        selectedCustomerId: props.selectedCustomerId,
        dataLoaded: false,
        selectedCustomer: {},
        contacts: [],
        filteredContacts: [],
        showContactDetailsSlider: false,
        contactEditMode: 'Add',
        showSendEmailSlider: false
    });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);
    const [contacts, setContacts] = useState([]);
    const [filteredContacts, setfilteredContacts] = useState([]);
    const { register, getValues, setValue, control, watch } = useForm();
    const [viewOnly, setViewOnly] = useState(userInfo.userType === 'admin' ? false : true);

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        getCompanyContacts(props.selectedCustomerId, userInfo.jobShopDivision);

    }, [props]);

    function replaceAll(string, search, replace) {
        return string.split(search).join(replace);
    }

    const columns = [
        {
            dataIndex: 'contact_id',
            title: 'contact_id',
            key: 'contact_id',
            hidden: true
        }, {
            dataIndex: 'jsS_name',
            title: 'Contact Name',
            key: 'jsS_name',
            sorter: (a, b) => a.jsS_name.localeCompare(b.jsS_name)
        }, {
            dataIndex: 'contact_type',
            title: 'Contact Type',
            key: 'contact_type',
            sorter: (a, b) => a.contact_type.localeCompare(b.contact_type)
        }, {
            dataIndex: 'title',
            title: 'Title',
            key: 'title'
        }, {
            dataIndex: 'division',
            title: 'Division',
            key: 'division',
            width: 100
        }, {
            dataIndex: 'phone',
            title: 'Phone',
            key: 'phone',
            width: 120,
            render: (phone) => {
                return (
                    <div>
                        {phone === null ? '' : <a href={`tel:${replaceAll(phone, "-", "")}`}>{phone}</a>}
                    </div>
                );
            }
        }, {
            dataIndex: 'ext',
            title: 'Ext.',
            key: 'ext',
            width: 100
        }, {
            dataIndex: 'cell',
            title: 'Cell',
            key: 'cell',
            width: 120,
            render: (cell) => {
                return (
                    <div>
                        {cell === null ? '' : <a href={`tel:${replaceAll(cell, "-", "")}`}>{cell}</a>}
                    </div>
                );
            }
        }, {
            title: 'Email',
            key: 'action',
            render: (record) => (
                <span className="btn btn-link btn-lg" onClick={(e) => emailContact(e, record)}>{record.email}</span>
                //<a href={`mailto:${email}`}> {email} </a>
            ),
        }, {
            title: 'Contact Details',
            key: 'action',
            width: 120,
            render: (record) => (
                <EditOutlined className="text-center hover" style={{ fontSize: '1.2rem' }} onClick={(e) => loadContactDetails(e, record)} />
            ),
            align: 'center'
        }, {
            title: 'Delete Contact',
            key: 'action',
            width: 120,
            //render: (record) => viewOnly ? null : <i id="deleteContact" className="far fa-trash-alt fa-1x ml-3 text-center hover text-danger" onClick={(e) => handleDeleteContact(e, record)}></i>,
            render: (record) => (
                <DeleteOutlined className="text-center hover" style={{ fontSize: '1.2rem', color: 'red' }} onClick={(e) => handleDeleteContact(e, record)} />
            ),
            align: 'center',
            hidden: viewOnly
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function handleAddNewContact() {
        setState({
            ...state,
            selectedItemId: null,
            selectedIdx: null,
            contactEditMode: 'Add',
            showContactDetailsSlider: true
        });
    }

    function loadContactDetails(e, record) {
        const selectedIdx = contacts.findIndex(con => con.contact_id === record.contact_id);

        setState({
            ...state,
            selectedItemId: record.contact_id,
            selectedIdx,
            contactEditMode: 'Edit',
            showContactDetailsSlider: true
        });
    }

    function handleDeleteContact(e, record) {
        confirm({
            title: "You are about to permanently delete this Contact",
            content: "Are you sure you would like continue?",
            buttons: ["Yes", "No"],
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                DeleteCustomerContact(record.contact_id)

                return 0;
            } else {
                return 0;
            }
        });
    }

    function DeleteCustomerContact(itemId) {
        let postdata = {};
        postdata.contact_id = itemId;

        Axios.post('/api/DeleteCustomerContact', postdata
        ).then(response => {
            let filteredcontacts = filteredContacts.filter(item => item.contact_id !== itemId)

            setfilteredContacts(filteredcontacts);

            setState({
                ...state,
                dataLoaded: true,
                showNoteDetailsSlider: false
            });

            toast.success('Contact deleted successfully');
        }).catch(error => {
            console.log(error);
        });
    }

    function emailContact(e, record) {
        setState({
            ...state,
            selectedContact: record,
            showSendEmailSlider: true
        });
    }

    async function updateTableDisplay(contacts, idxToDirectTo = null) {
        let refreshedcontacts = [...contacts];

        setfilteredContacts(refreshedcontacts);
    }

    async function reloadContactsFromDB() {
        getCompanyContacts(props.selectedCustomerId, userInfo.jobShopDivision);
    }

    function getCompanyContacts(company_id, jobshopdivision) {
        let postdata = {};
        postdata.company_id = company_id;
        postdata.jobshopdivision = jobshopdivision;

        Axios.post(`/api/GetCompanyContacts`, postdata
        ).then(response => {
            let contacts = response.data;

            setContacts(contacts);
            setfilteredContacts(contacts);

            setState({
                ...state,
                contacts,
                dataLoaded: true,
                showContactDetailsSlider: false
            });

        }).catch(error => {
            console.log(error);
        });
    }

    console.log(state.selectedContact);

    return (
        <div className="row">
            <Spin spinning={!state.dataLoaded}>
                <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                    <Alert
                        message="Loading Contacts"
                        description="Please stand by while we retrieve contacts for this customer"
                        type="info"
                    />
                </div>
                {state.dataLoaded &&
                    <Fragment>
                        <div className="row">
                            <h5 className="frame-heading mt-3">
                                -- CONTACTS --
                            </h5>
                            <div className="col-sm-10 col-md-10 col-lg-10">
                                {filteredContacts.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                        <span>
                                            No Contacts Found For This Customer
                                        </span>
                                    } />
                                    :
                                <Table className="custom-ant-selection"
                                    rowKey={item => item.contact_id}
                                    rowSelection={rowSelection}
                                    hideSelectionColumn={true}
                                    bordered
                                    dataSource={filteredContacts}
                                    columns={columns}
                                    pagination={{
                                        defaultPageSize: 10,
                                        showSizeChanger: true,
                                        pageSizeOptions: ['10', '25', '50', '100'],
                                        showTotal: (total, range) => (
                                            <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                Showing {range[0]}-{range[1]} of {total}
                                            </span>
                                        )
                                    }}
                                    onRow={(record) => {
                                        return {
                                            onClick: () => {
                                                let selRows = [record.contact_id];
                                                setSelectedRowKeys([...selRows]);
                                            },
                                            onDoubleClick: (e) => {
                                                loadContactDetails(e, record);
                                            }
                                        }
                                    }}
                                />
                                }
                            </div>
                            <div className="col-sm-2 col-md-2 col-lg-2 text-left">
                                {userInfo.userType === 'admin' &&
                                    <div><button className="btn btn-submit mt-4" onClick={handleAddNewContact}>Add New Contact</button></div>
                                }
                            </div>
                        </div>
                    </Fragment>
                }
            </Spin>
            {state.showContactDetailsSlider &&
                <CustomerContactDetailsSlider showPane={state.showContactDetailsSlider} mode={state.contactEditMode} callingComponent={'CustomerContacts'} contacts={state.contacts} selectedIdx={state.selectedIdx} selectedItemId={state.selectedItemId}
                    updateTableDisplay={updateTableDisplay} reloadContactsFromDB={reloadContactsFromDB} selectedCustomerId={props.selectedCustomerId} hidePane={() => setState({ ...state, showContactDetailsSlider: false })} />
            }
            {state.showSendEmailSlider &&
                <SendEmailSlider selectedContact={state.selectedContact} selectedCustomer={props.selectedCustomer} showPane={state.showSendEmailSlider} hidePane={() => setState({ ...state, showSendEmailSlider: false })} />
            }
        </div>
    );
}