import React, { useState } from 'react';
import { Divider, Popover } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useForm } from "react-hook-form";
import { toast } from '@rickylandino/react-messages';

export function ApproveDenyOrRequestMoreInfoButton(props) {
    const { register, watch, setValue } = useForm();

    const action = watch("action", props.drivers, "");
    const comments = watch("comments", props.drivers);

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [open, setOpen] = useState(false);

    const hide = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    function handleSubmit() {
        let c = '';

        if (comments) {
            c = `${userInfo.fullName} Commented: ${comments}`;
        }

        if (action === undefined || action === '') {
            toast.error('You must choose an action in order to complete the task');
            return;
        }

        if (action) {
            if (c) {
                c = `${userInfo.fullName} Selected: ${action}. Additional Comments: ${comments}`;
            } else {
                c = `${userInfo.fullName} Selected: ${action}`;
            }
        }

        //console.log(props.worklistItem);
        //console.log(action);
        //console.log(comments);

        props.handleCompleteTask(action, comments);

//        //if not customer change then contact change, if this comp needed again refactor to switch statement?
//        if (props.handleProcessJSSSalesOrder) {
///*            console.log('ApproveDenyButton - Customer Change');*/
//            props.handleProcessJSSSalesOrder(props.worklistItem, action, comments);
//        } else {
//            //console.log('ApproveDenyButton - Contact Change');
//            //console.log('testing comments', comments);
//            //console.log('testing action', action);
//            //props.handleProcessContactChange(action, comments);
//        }

        
        setOpen(false);
        setValue('action', '');
        setValue('comments', '');
    }

    return (
        <>
            {props.worklistItem?.worklistItem?.disabled ?
                <span>With Sales Rep</span>
                :
                <Popover
                    content={
                        <>
                            <div className="form-group">
                                <label className="form-label">Choose an action</label>
                                <div>
                                    <input type="radio" name="action" value="Approved" {...register('action')} /> &nbsp; Approve
                            </div>

                                <div>
                                    <input type="radio" name="action" value="Denied" {...register('action')} /> &nbsp; Deny
                            </div>

                                <div>
                                    <input type="radio" name="action" value="Request More Info" {...register('action')} /> &nbsp; Request More Info
                            </div>
                            </div>

                            <div className="form-group">
                                <label className="form-label">Comments</label>
                                <textarea type="text" className="form-control-custom" {...register('comments')} />
                            </div>

                            <Divider />

                            <div className="form-group">
                                <button className="btn btn-submit btn-sm margin-left-15" onClick={handleSubmit}>Submit</button>
                                <CloseOutlined className="float-end hover" onClick={hide} />
                            </div>
                        </>
                    }
                    trigger="click"
                    visible={open}
                    onOpenChange={handleOpen}
                >
                    <button className={`btn btn-submit btn-sm margin-left-15 float-left ${props.disabled ? 'disabled' : ''}`} onClick={handleOpen}>Complete Task</button>
                </Popover>
            }
            
        </>
    );
};