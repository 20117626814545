import { toast } from '@rickylandino/react-messages';
import { Drawer, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { useForm } from "react-hook-form";
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Axios from '../../config/axios';
import { assignWorklistItem, insertNewWorklistItem, adminApproveDenyRequestMoreInfo} from '../../services/WorklistService';
import { ApproveDenyOrRequestMoreInfoButton } from '../Dashboard/Buttons/ApproveDenyOrRequestMoreInfoButton';

export default function JSS_SalesOrderWFSliderV2(props) {
    const [state, setState] = useState({
        showPane: false,
        showListLoaded: false,
        dataLoaded: false,
        submitButtonText: '',
        searchForm: {},
        searchFormInitialState: {},
        selectedWorklistItem: {}
    });

    const { register, getValues, setValue, control, watch } = useForm();
    const [futureShows, setFutureShows] = useState([]);
    const [customPackageSelected, setCustomPackageSelected] = useState(false);
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    var isDirty = useRef(false);

    useEffect(() => {
        //console.log(props.selectedCustomerId);

        var mySelectedWorklistItem;

        if (props.selectedWorklistItem === undefined) {
            mySelectedWorklistItem = null;
        }
        else {
            mySelectedWorklistItem = props.selectedWorklistItem.worklistItem;
        }

        GetFutureShowList(props.selectedCustomerId, mySelectedWorklistItem);
    }, []);

    useEffect(() => {
        if (state.showListLoaded === true) {
            if (state.selectedWorklistItem && props.showPane) {
                displayChangeRequests();
            }
        }
    }, [state.showListLoaded]);

    function hidePane() {
        setState({
            ...state,
            showPane: false
        });

        setTimeout(() => {
            props.hidePane();
        }, 1000);
    }

    async function displayChangeRequests() {
        /*
         *   if this slider is being viewed from the worklist dashboard
         *   set the formfields to the contact details with the pending 
         *   change requests highlighted
         */

        let w = state.selectedWorklistItem;
        var salesOrder = JSON.parse(w.newValue);

        console.log(salesOrder);

        const lowerCase = str => str[0].toLowerCase() + str.slice(1);
        salesOrder = Object.fromEntries(Object.entries(salesOrder).map(
            ([k, v]) => [lowerCase(k), v])
        );

        console.log(salesOrder.searchForm.selectedPackageType);

        setValue('formFields.selectedShow', salesOrder.searchForm.selectedShow);
        setValue('formFields.selectedSalesStatus', salesOrder.searchForm.selectedSalesStatus);
        setValue('formFields.booth', salesOrder.searchForm.booth);
        setValue('formFields.selectedPackageType', salesOrder.searchForm.selectedPackageType);
        setValue('formFields.weblink', salesOrder.searchForm.weblink);
        setValue('formFields.noshowleads', salesOrder.searchForm.noshowleads);
        setValue('formFields.leadretrieval', salesOrder.searchForm.leadretrieval);
        setValue('formFields.notes', salesOrder.searchForm.notes);

        setState({
            ...state
        })           
    }

    function GetFutureShowList(selectedCustomerId, worklistItem) {
        Axios.get(`/api/GetShowsForNewSalesOrder`, {
            params: {
                companyid: selectedCustomerId
            }
        }).then(response => {
            setFutureShows(response.data);
            
            setState({
                ...state,
                selectedWorklistItem: worklistItem,
                dataLoaded: true,
                submitButtonText: 'Submit Workflow',
                showListLoaded: true,
                showPane: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function screenDataOK() {
        let errString = '';
        let mySelectedShowName = '';

        if (getValues().formFields.selectedShow === 'Select Show') {
            errString += 'An upcoming show must be selected<br />';
        }

        futureShows.forEach((show, index, array) => {
            if (show.showcode.toString() === getValues().formFields.selectedShow.toString()) {
                mySelectedShowName = show.name;
                if (getValues().formFields.selectedSalesStatus === 'Yes' && show.invoiced === 'Yes' && (getValues().formFields.booth === null || getValues().formFields.booth.trim() === '')) {
                    errString += show.name + ' has been invoiced - a booth # must be provided <br />';
                }
            }
        });

        if (getValues().formFields.selectedPackageType === null || getValues().formFields.selectedPackageType === undefined) {
            errString += 'A package type must be selected for ' + mySelectedShowName + '<br />';
        }

        if (errString !== '') {
            var htmlErrorContent = <div dangerouslySetInnerHTML={{ __html: errString }} />

            toast.error(htmlErrorContent);
            return false;
        }
        else {
            return true;
        }
    }

    function handleSubmitWorkflowRequest() {
        if (screenDataOK() === false) {
            return;
        }

        let wfStateInitial = {};
        wfStateInitial.searchFormInitialState = state.searchFormInitialState;

        let wfState = {};
        wfState.searchForm = {
            selectedShow: getValues().formFields.selectedShow,
            selectedSalesStatus: getValues().formFields.selectedSalesStatus,
            selectedPackageType: getValues().formFields.selectedPackageType,
            booth: getValues().formFields.booth,
            weblink: getValues().formFields.weblink,
            noshowleads: getValues().formFields.noshowleads,
            leadretrieval: getValues().formFields.leadretrieval,
            notes: getValues().formFields.notes
        };

        let postdata = {
            originatorUsername: userInfo.userId,
            company_Id: props.selectedCustomerId,
            itemDetail: "Process JSS Sales Order",
            oldValue: JSON.stringify(wfStateInitial),
            newValue: JSON.stringify(wfState)
        }

        insertNewWorklistItem(postdata).then(data => {
            if (data) {
                toast.success("Sales Order submitted successfully");

                setState({
                    ...state,
                    showPane: false
                });
            } else {
                toast.error("Something went wrong");
            }
        }).catch(error => {
            toast.error("Something went wrong");
        });

        //Axios.post(`/api/SendSalesOrderNotificationEmail`, postdata
        //).then(response => {
        //    if (response.data === true) {
        //        hidePane();
        //        toast.success("Sales Order Processed Successfully");
        //    }
        //    else {
        //        toast.error("Error Processing Sales Order");
        //    }
        //}).catch(error => {
        //    console.log(error);
        //});    
    }

    function handleCompleteTask(action, comments) {
        if (screenDataOK() === false) {
            return;
        }

        let wfStateInitial = {};
        wfStateInitial.searchFormInitialState = state.searchFormInitialState;

        let wfState = {};
        wfState.searchForm = {
            selectedShow: getValues().formFields.selectedShow,
            selectedSalesStatus: getValues().formFields.selectedSalesStatus,
            selectedPackageType: getValues().formFields.selectedPackageType,
            booth: getValues().formFields.booth,
            weblink: getValues().formFields.weblink,
            noshowleads: getValues().formFields.noshowleads,
            leadretrieval: getValues().formFields.leadretrieval,
            notes: getValues().formFields.notes
        };

        //the approver can change/override fields so get the latest screen fields to send to the API
        var myselectedWorklistItem = { ...state.selectedWorklistItem };
        myselectedWorklistItem.oldValue = JSON.stringify(wfStateInitial);
        myselectedWorklistItem.newValue = JSON.stringify(wfState);
        console.log(myselectedWorklistItem);

        let postdata = {
            worklistItem: myselectedWorklistItem,
            action: action,
            comments: comments,
        }

        adminApproveDenyRequestMoreInfo(postdata)
            .then(response => {
                props.refreshDashboard();
                toast.success("Request Processed")
                hidePane();
                //props.getWorklistByUser();
            }).catch(error => {
                //setLoading(false);
                toast.error("Problem Encountered During Request Processing");
            });
    }

    function handleProcessJSSSalesOrder(worklistItem, action, comments, addSystemNote) {
        toast.success("Request Processed");

        //this get's called as a prop from ApproveDenyOrRequestMoreInfoButton.js

        //let formFields = { ...state.formFields };

        ////if not a submission from the worklist dashboard for approval or denial
        //if (!props.worklistItem.assignedToUsername === uInfo.username) { return; }

        //if (!formFields.name || formFields.customerType === null || formFields.customerType === '' || formFields.groupCode === 'Select' || !formFields.territoryID) {
        //    toast.error("Mandatory fields missing!");
        //    return;
        //} else if (readyForApproval && !formFields.custID && action === 'Approved') {
        //    toast.error("Cust ID missing!");
        //    return;
        //}

        //setLoading(true);

        let postdata = {
            worklistItem,
            action,
            comments: comments
        };
        console.log(postdata);
        
        //adminApproveDenyRequestMoreInfo(postdata)
        //    .then(response => {
        //        setLoading(false);
        //        toast.success("Request Processed")
        //        hidePane();
        //        props.getWorklistByUser();
        //    }).catch(error => {
        //        setLoading(false);
        //        toast.error("Problem Encountered During Request Processing");
        //    });

    }

    function handlePackageTypeChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setValue('formFields.selectedPackageType', value);

        if (event.target.value === 'Basic') {
            setValue('formFields.noshowleads', false);
            setValue('formFields.weblink', false);
        }

        setState({
            ...state,
            searchForm: { ...state.searchForm, [name]: value }
        });
    }

    function handleOptionsChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        //console.log(target.name);
        //console.log(value);

        setValue(target.name, value);

        setState({
            ...state,
            disableAltAddressFields: !value,
            searchForm: { ...state.searchForm, [name]: value }
        });
    }

    function handleAssignWorklistItem(row) {
        let worklistItem = { ...row };
        worklistItem.assignedToUsername = userInfo.userId;

        assignWorklistItem(worklistItem).then(data => {
            props.refreshDashboard(data);
            toast.success("Worklist Item has been assigned to you");

            setState({
                ...state,
                selectedWorklistItem: worklistItem
            });
        }).catch(error => {
            toast.error("Something went wrong");
        });
    }

    return (
        <Drawer
                closeIcon={<i className="fas fa-chevron-right float-left"></i>}
                title='Exhibitor Sales Order Entry'
                placement={'right'}
                onClose={hidePane}
                visible={state.showPane}
                key='createSalesOrder'
                width={'30%'}

                footer={
                    <div className="form-group col-12 padding-25-10">
                        {!state.selectedWorklistItem ? (
                            <>
                                <button id="btnSaveChanges" onClick={handleSubmitWorkflowRequest} className="btn btn-submit me-3" style={{ 'marginLeft': '5px' }}>{state.submitButtonText}</button>
                                <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                            </>
                        ) : (
                            <>
                                {state.selectedWorklistItem?.assignedToUsername === 'JSS Admin Review' && (
                                    <>
                                        <button className="btn btn-submit btn-sm margin-left-15 float-left" onClick={() => { handleAssignWorklistItem(state.selectedWorklistItem); }}>I'll Take It</button>&nbsp;&nbsp;&nbsp;
                                        <button className="btn btn-outline-primary btn-sm margin-left-15 float-right" onClick={hidePane}>Close</button>
                                    </>
                                )}

                                {state.selectedWorklistItem?.assignedToUsername === userInfo.userId && (
                                    <>
                                        <ApproveDenyOrRequestMoreInfoButton worklistItem={state.selectedWorklistItem} handleCompleteTask={handleCompleteTask} />
                                        &nbsp;&nbsp;&nbsp;
                                        <button className="btn btn-outline-primary btn-sm margin-left-15 float-right" onClick={hidePane}>Close</button>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                }
            >
            <div className="slide-pane-body">
                {state.dataLoaded &&
                    <div className="row">
                        {props.selectedWorklistItem &&
                            <>
                                <div className="form-group col-lg-12">
                                    <h5>Customer: {props.selectedWorklistItem.customerName}</h5>
                                </div>
                                <div className="form-group col-lg-12">
                                    <h5>Requestor: {props.selectedWorklistItem.requestor}</h5>
                                </div>
                            </>
                        }
                        <div className="form-group col-lg-6">
                            <label className="form-label">Select An Upcoming Show</label>
                            <select className="form-control-custom" {...register("formFields.selectedShow")}>
                                <option value='Select Show'>Select Show</option>
                                {futureShows.map((show, idx) => <option key={idx} value={show.showcode}>{show.name}</option>)}
                            </select>
                        </div>
                        <div className="form-group col-lg-6"></div>

                        <div className="form-group col-lg-3">
                            <label className="form-label">Select Sales Status</label>
                            <select className="form-control-custom" {...register("formFields.selectedSalesStatus")} >
                                <option value="Select">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="Interested">Interested</option>
                                {/*<option value="Waitlist" style={show.invoiced === 'No' ? { display: 'none' } : {}}>Waitlist</option>*/}
                            </select>
                        </div>
                        <div className="form-group col-lg-9"></div>

                        <div className="form-group col-lg-3">
                            <label className="form-label">Booth #</label>
                            <input type="text" name="booth" {...register("formFields.booth")} className="form-control-custom" />
                        </div>
                        <div className="form-group col-lg-9"></div>

                        <div className="form-group col-lg-12">
                            <label className="form-label">Package Type</label>
                                <div><input type="radio" name="packageType" value="Basic" className="mt-1" onChange={handlePackageTypeChange} checked={getValues().formFields.selectedPackageType === 'Basic'} />&nbsp;<label className="form-label">Basic Package</label></div>
                                <div><input type="radio" name="packageType" value="Custom" className="mt-1" onChange={handlePackageTypeChange} checked={getValues().formFields.selectedPackageType === 'Custom'} />&nbsp;<label className="form-label">Custom Package</label></div>
                            <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-2">
                                    <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="weblink" {...register("formFields.weblink")} checked={getValues().formFields.weblink} onChange={handleOptionsChange} disabled={getValues().formFields.selectedPackageType !== 'Custom' || getValues().formFields.selectedPackageType === null ? true : false} />
                                        <label className="custom-control-label">Weblink</label>
                                    </div>
                                </div>
                                <div class="col-md-9">
                                    <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="noshowleads" {...register("formFields.noshowleads")} checked={getValues().formFields.noshowleads} onChange={handleOptionsChange} disabled={getValues().formFields.selectedPackageType !== 'Custom' || getValues().formFields.selectedPackageType === null ? true : false} />
                                        <label className="custom-control-label">No Show Leads</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group col-lg-12">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" name="leadretrieval" {...register("formFields.leadretrieval")} checked={getValues().formFields.leadretrieval} onChange={handleOptionsChange} />
                                <label className="custom-control-label fw-bold">Lead Retrieval</label>
                            </div>
                        </div>

                        <div className="col-lg-12">
                            <label className="form-label">Notes</label>
                        <textarea style={{ width: '100%', height: '200px' }} name="notes" placeholder="" {...register("formFields.notes")} />
                        </div>
                    </div>
                }
            </div>
            </Drawer>
    );
}