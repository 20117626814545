import Axios from '../config/axios';

export function getUnreadAlertsByUser(userId) {
    console.log(userId);

    return Axios.get('/api/GetUnreadAlertsByUser', {
        params: {
            userId
        }
    }).then(response => response.data).catch(error => error);
}

export function markAlertRead(alert) {
    return Axios.put('/api/MarkAlertRead', alert).then(response => response.data).catch(error => error);
}