import React, { useEffect, useState, useRef } from 'react';
import { Input, AutoComplete, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { highlightContent } from '../Helpers/Highlight';
import Axios from '../../config/axios';
import { useNavigate } from "react-router-dom";

const antIcon = <LoadingOutlined spin />;

function CustomSearch(props) {
    const navigate = useNavigate();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [state, setState] = useState({
        results: [],
        searchValue: '',
        open: false
    });

    const [searchResults, setSearchResults] = useState({
        results: [],
        loaded: false
    });

    useEffect(() => {
        setState({
            ...state,
            searchValue: ''
        });

        setSearchResults({
            results: [],
            loaded: true
        });
    }, [props]);

    function performSearch(value) {
        Axios.get(`/api/GetSearchResults`, {
            params: {
                searchValue: value,
                jobshopdivision: userInfo.jobShopDivision
            }
        }).then(response => {
            let results = [];

            if (response.data.customers?.length > 0) {

                var customerObj = {
                    label: userInfo.jobShopDivision === 'JSS' ? 'Exhibitors' : 'Advertisers',
                    options: response.data.customers.map(item => renderCustomer(item, value))
                }

                results.push(customerObj);
            }

            if (response.data.prospects?.length > 0) {

                var prospectObj = {
                    label: userInfo.jobShopDivision === 'JSS' ? 'Exhibitor Prospects' : 'Advertiser Prospects',
                    options: response.data.prospects.map(item => renderProspect(item, value))
                }

                results.push(prospectObj);
            }

            if (response.data.contacts?.length > 0) {

                var contactObj = {
                    label: userInfo.jobShopDivision === 'JSS' ? 'JSS Contacts' : 'JST Contacts',
                    options: response.data.contacts.map(item => renderContact(item, value))
                }

                results.push(contactObj);
            }

            if (response.data.invoices?.length > 0 && userInfo.jobShopDivision === 'JSS') {

                var invoiceObj = {
                    label: 'Invoices',
                    options: response.data.invoices.map(item => renderInvoice(item, value))
                }

                results.push(invoiceObj);
            }

            if (response.data.jstsalesorders?.length > 0 && userInfo.jobShopDivision === 'JST') {

                var soObj = {
                    label: 'JST Sales Orders',
                    options: response.data.jstsalesorders.map(item => renderSalesOrder(item, value))
                }

                results.push(soObj);
            }

            setSearchResults({
                results,
                loaded: true
            });

        }).catch(error => {
            console.log(error);
        });
    }

    function renderCustomer(customer, searchValue) {
        //var highlight = highlightContent(customer.name + ' - ' + customer.city + ', ' + customer.state + ' (Id: ' + customer.id + ')', searchValue);
        var item = customer.name + ' - ' + customer.city + ', ' + customer.state + ' (Id: ' + customer.id + ')';

        return {
            value: customer.id,
            customerid: customer.id,
            type: 'customer',
            label: (
                <div>
                    {item}
                    {/*{highlight.map((item, idx) => <span key={idx} className={item.highlight ? 'highlight' : ''}>{item.text}</span>)}*/}
                </div>
            )
        }
    }

    function renderProspect(customer, searchValue) {
        //var highlight = highlightContent(customer.name + ' - ' + customer.city + ', ' + customer.state + ' (Id: ' + customer.id + ')', searchValue);
        var item = customer.name + ' - ' + customer.city + ', ' + customer.state + ' (Id: ' + customer.id + ')';

        return {
            value: customer.id,
            customerid: customer.id,
            type: 'prospect',
            label: (
                <div>
                    {item}
                    {/*{highlight.map((item, idx) => <span key={idx} className={item.highlight ? 'highlight' : ''}>{item.text}</span>)}*/}
                </div>
            )
        }
    }

    function renderContact(contact, searchValue) {
        var highlight = '';
        var item;

        //if (userInfo.jobShopDivision === 'JSS') {
        //    highlight = highlightContent(contact.jsS_name + '(' + contact.email + ')', searchValue);
        //}
        //else {
        //    highlight = highlightContent(contact.jsT_first_name + ' ' + contact.jsT_last_name + '(' + contact.email + ')', searchValue);
        //}

        if (userInfo.jobShopDivision === 'JSS') {
            item = contact.jsS_name + '(' + contact.email + ')';
        }
        else {
            item = contact.jsT_first_name + ' ' + contact.jsT_last_name + '(' + contact.email + ')';
        }

        return {
            value: contact.contact_id,
            customerid: contact.company_id,
            type: 'contact',
            label: (
                <div>
                    {item}
                    {/*{highlight.map((item, idx) => <span key={idx} className={item.highlight ? 'highlight' : ''}>{item.text}</span>)}*/}
                </div>
            )
        }
    }

    function renderInvoice(invoice, searchValue) {
        //var highlight = highlightContent(invoice.invoiceNumber);
        var item = invoice.invoiceNumber;

        return {
            value: invoice.invoiceNumber,
            customerid: invoice.id,
            type: 'invoice',
            label: (
                <div>
                    {item}
                    {/*{highlight.map((item, idx) => <span key={idx} className={item.highlight ? 'highlight' : ''}>{item.text}</span>)}*/}
                </div>
            )
        }
    }

    function renderSalesOrder(salesorder, searchValue) {
        //var highlight = highlightContent(salesorder.sales_order_id);
        var item = salesorder.sales_order_id;

        return {
            value: salesorder.sales_order_id,
            customerid: salesorder.advertiser_company_id,
            type: 'salesorder',
            label: (
                <div>
                    {item}
                    {/*{highlight.map((item, idx) => <span key={idx} className={item.highlight ? 'highlight' : ''}>{item.text}</span>)}*/}
                </div>
            )
        }
    }

    function handleSelect(option, searchItem) {
        setState({
            ...state,
            searchValue: '',
            open: false
        });


        setSearchResults({
            results: []
        });

        navigate("/customerview", { state: { selectedCustomerId: searchItem.customerid, callingComponent: 'CustomSearch' } });
    }

    function handleClickSearch(value, event) {
        if (!event) {
            if (!value) {
                setState({
                    ...state,
                    open: false,
                    searchValue: value
                });
            } else {
                setState({
                    ...state,
                    searchValue: value
                });
            }
        }
        if (event) {
            setSearchResults({
                ...searchResults,
                results: [],
                loaded: false
            });

            performSearch(value);

            setState({
                ...state,
                open: true
            });
        }
    }

    var results = state.results;

    const renderTitle = (title) => (
        <span>
            {title}
        </span>
    );

    const renderItem = (item) => ({
        value: item.companyId.toString(),
        label: (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                {item.name}
            </div>
        ),
    });

    let list = [];
    results.forEach(c => {
        list.push(renderItem(c));
    });

    const options = [
        {
            label: renderTitle('Customers'),
            options: list,
        },
    ];

    return (
        <>
            <AutoComplete
                autoFocus
                dropdownClassName="certain-category-search-dropdown"
                dropdownMatchSelectWidth={500}
                style={{
                    width: '100%',
                }}
                onSearch={handleClickSearch}
                options={searchResults.results}
                notFoundContent="No Results"
                onSelect={handleSelect}
                value={state.searchValue}
                open={state.open}
                onBlur={() => setState({ ...state, open: false })}
                notFoundContent={searchResults.loaded ? "No Results Found" : <div><Spin indicator={antIcon} /> Searching...</div>}
            >
                <Input.Search size="large" enterButton={true} onSearch={handleClickSearch} placeholder={userInfo.jobShopDivision === 'JSS' ? 'Search for Exhibitors' : 'Search for Advertisers'} />
                </AutoComplete>
        </>
    );
}

export default CustomSearch;