import React, { useEffect, useState, useRef } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Fragment } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Moment from 'moment';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Empty, Spin, Alert, Table, Input, Badge, LockOutlined } from 'antd';
import NoteDetailsSlider from './NoteDetailsSlider';
import { CommentOutlined } from '@ant-design/icons';
import { persistentNotes } from '../../services/PersistNotes';
import { persistentFilteredNotes } from '../../services/PersistNotes';
import { notesFilterType } from '../../services/PersistNotes';
import { DeleteOutlined, EditOutlined, RedoOutlined } from '@ant-design/icons';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { useRecoilState, useRecoilValue } from 'recoil';

//import ReactExport from "react-data-export";

//const ExcelFile = ReactExport.ExcelFile;
//const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
//const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function CustomerNotes(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        selectedCustomerId: props.selectedCustomerId,
        dataLoaded: false,
        selectedCustomer: {},
        notesFilterType: 'allDivisionNotes',
        selectedItemId: null,
        selectedNote: {},
        showNoteDetailsSlider: false,
        noteEditMode: 'Add',
        selectedIdx: null,
        pinnedNoteText: '',
        pinnedNoteItemId: null,
        pinnedNoteDisplayMode: 'none',
        searchValue: '',
        filteredNotes: [],
        noteQueue: [],
        notesDialogDisplayMode: ''
    });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    const [notes, setNotes] = useState([]);
    const [filteredNotes, setfilteredNotes] = useState([]);

    const [groupedNotes, setGroupedNotes] = useState({});

    const [localPersistentNotes, setPersistentNotes] = useRecoilState(persistentNotes);
    const [localPersistentFilteredNotes, setPersistentFilteredNotes] = useRecoilState(persistentFilteredNotes);
    const [localNotesFilterType, setNotesFilterType] = useRecoilState(notesFilterType);
    const [viewOnly, setViewOnly] = useState([]);
    const [showDeletedNotes, setShowDeletedNotes] = useState(false);

    var urlend = window.api.slice(-1) === '/' ? 'hubs/notes' : '/hubs/notes';
    const url = window.api + urlend;

    const connection = new HubConnectionBuilder()
        .configureLogging(LogLevel.Error)
        .withUrl(url)
        .withAutomaticReconnect()
        .build();

    useEffect(() => {
        getCustomerNotes(props.selectedCustomerId, userInfo.jobShopDivision, userInfo.userId, state.notesFilterType);
    }, [showDeletedNotes]);

    useEffect(() => {
        setNotesFilterType('allDivisionNotes');
        getCustomerNotes(props.selectedCustomerId, userInfo.jobShopDivision, userInfo.userId, 'allDivisionNotes');

        if (userInfo.userType === 'admin') {
            setViewOnly(false);
        }
        else {
            setViewOnly(true);
        }

        connection.start()
            .then(result => {
                connection.on('UpdateCustomerNotes', custId => {
                    if (custId === props.selectedCustomerId) {
                        //console.log('same from hub: ' + custId.toString());
                        //console.log('same props: ' + props.selectedCustomerId.toString());

                        getCustomerNotes(props.selectedCustomerId, userInfo.jobShopDivision, userInfo.userId, localNotesFilterType);
                    }
                    //else {
                    //    console.log('different from hub: ' + custId.toString());
                    //    console.log('different props: ' + props.selectedCustomerId.toString());
                    //}
                });
            })
            .catch(e => {
                console.log("SignalR Error!");
                console.log('Connection failed: ', e);
            });
    }, [props]);

    function getCustomerNotes(id, division, user, notesfiltertype) {
        //setState({
        //    ...state,
        //    dataLoaded: false,
        //    showNoteDetailsSlider: false
        //});

        let postdata = {};
        postdata.userid = user;
        postdata.company_id = id;
        postdata.jobshopdivision = division;
        postdata.notesfiltertype = notesfiltertype;
        postdata.showdeletednotes = showDeletedNotes;

        Axios.post(`/api/GetCustomerNotes`, postdata
        ).then(response => {
            const notes = response.data.displayList;

            setGroupedNotes(response.data);

            var modifiedObj = { ...response.data };
            delete modifiedObj.displayList;

            var pinnedNoteText = '';
            var pinnedNoteItemId = null;
            var pinnedNoteDisplayMode = 'none';

            for (const [key, value] of Object.entries(modifiedObj)) {
                for (var i = 0, l = value.length; i < l; i++) {
                    if (value[i].pinned === true) {
                        pinnedNoteText = value[i].note;
                        pinnedNoteItemId = value[i].itemId;
                        pinnedNoteDisplayMode = undefined;

                        break;
                    }
                }
            }
            
            //setNotes(notes);
            //setfilteredNotes(notes);

            setPersistentNotes(notes);
            setPersistentFilteredNotes(notes);

            setState({
                ...state,
                notes,
                selectedCustomerId: location.state.selectedCustomerId,
                notesFilterType: notesfiltertype,
                dataLoaded: true,
                showNoteDetailsSlider: false,
                pinnedNoteText,
                pinnedNoteItemId,
                pinnedNoteDisplayMode,
                searchValue: ''
            });

            ////Resets the search filter when Exhibitor changes. This uses the Ref for the Notes SearchBar
            //if (notesSearchBar.current) {
            //    notesSearchBar.current.props.onSearch('');
            //}

        }).catch(error => {
            console.log(error);
        });
    }

    function handleNotesFilterChange(e) {
        setNotesFilterType(e.target.value);

        switch (e.target.value) {
            case 'allDivisionNotes':
                getCustomerNotes(location.state.selectedCustomerId, userInfo.jobShopDivision, "", e.target.value);
                break;
            case 'myNotes':
                getCustomerNotes(location.state.selectedCustomerId, "", userInfo.userId, e.target.value);
                break;
            case 'systemNotes':
                getCustomerNotes(location.state.selectedCustomerId, userInfo.jobShopDivision, "system", e.target.value);
                break;
            case 'allNotes':
                getCustomerNotes(location.state.selectedCustomerId, "", "system", e.target.value);
                break;
            default:
                break;
        }
    }

    const columns = [
        {
            dataIndex: 'itemId',
            title: 'itemId',
            key: 'itemId',
            hidden: true
        }, {
            dataIndex: 'groupId',
            title: 'groupId',
            key: 'groupId',
            hidden: true
        }, {
            dataIndex: 'note_date',
            title: 'Date',
            key: 'note_date',
            width: 120,
            sorter: (a, b) => new Date(a.note_date) - new Date(b.note_date),
            render: (note_date) => {
                return (
                    <div>
                        {/*{note_date === null ? '' : Moment(note_date).toLocaleTimeString()}*/}
                        {note_date === null ? '' : Moment(dateWithNoTimezone(note_date)).format("L")}
                    </div>
                );
            }
        }, {
            dataIndex: 'salesperson',
            title: 'User',
            key: 'salesperson',
            sorter: (a, b) => a.salesperson.localeCompare(b.salesperson),
            width: 120,
        }, {
            dataIndex: 'note',
            title: 'Note',
            key: 'note',
            sorter: (a, b) => a.note.localeCompare(b.note),
            render: (note) => {
                return (
                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: '2' }} dangerouslySetInnerHTML={{ __html: note.replace(/(<([^>]+)>)/gi, " ") }} />
                );
            },
            //ellipsis: true,
            /*            width: 725,*/
            onCell: () => {
                return {
                    style: {
                        whiteSpace: 'normal',
                        display: 'flex'
                    }
                }
            },
        },
        {
            dataIndex: 'linkedNotesCount',
            key: 'linkedNotesCount',
            title: 'Dialog',
            render: (value, record) => (
                value > 1 && <span className="hover"><Badge size="default" count={value} color="#275282" onClick={(e) => handleEditNote(e, record)}><CommentOutlined style={{ fontSize: '1.2rem' }} /></Badge></span>
            ),
            align: 'center',
            width: 120
        },
        {
            title: 'Note Details',
            key: 'action',
            width: 120,
            render: (record) => (
                <EditOutlined className="text-center hover" style={{ fontSize: '1.2rem' }} onClick={(e) => handleEditNote(e, record)} />
            ),
            align: 'center'
        }, {
            title: 'Delete',
            key: 'action2',
            width: 120,
            render: (record) => record.salesperson === userInfo.userId || viewOnly === false ? <DeleteOutlined className="text-center hover" style={{ fontSize: '1.2rem', color: 'red' }} onClick={(e) => handleDeleteNote(e, record)} /> : null,
            align: 'center',
            hidden: showDeletedNotes
        }, {
            title: 'Deleted',
            key: 'action3',
            render: (record) => (
                <div><span style={{ color: 'red' }}>Deleted By:</span>&nbsp;{record.deletedBy} on {Moment.utc(record.deletedOn).format('YYYY-MM-DD HH:mm:ss A')}</div>
            ),
            align: 'center',
            width: 200,
            hidden: !showDeletedNotes
        }, {
            title: 'Restore',
            key: 'action2',
            width: 120,
            render: (record) => <RedoOutlined className="text-center hover" style={{ fontSize: '1.2rem' }} onClick={(e) => handleUnDeleteNote(e, record)} />,
            align: 'center',
            hidden: !showDeletedNotes
        }, {
            dataIndex: 'deleted',
            title: 'deleted',
            key: 'deleted',
            hidden: true
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function handleAddNewNote() {
        setState({
            ...state,
            selectedItemId: null,
            selectedIdx: null,
            noteEditMode: 'Add',
            showNoteDetailsSlider: true,
            noteQueue: [],
            notesDialogDisplayMode: 'Editable'
        });
    }

    function handleEditNote(e, record) {
        var myNotesDialogDisplayMode;
        if (record.deleted) {
            myNotesDialogDisplayMode = 'ViewOnly';
        }
        else {
            myNotesDialogDisplayMode = 'Editable';
        }

        var noteQueue = groupedNotes[record.groupId];

        setState({
            ...state,
            selectedItemId: record.itemId,
            //selectedIdx: rowIndex,
            noteEditMode: 'Edit',
            showNoteDetailsSlider: true,
            noteQueue,
            notesDialogDisplayMode: myNotesDialogDisplayMode
        });
    }

    function handleDeleteNote(e, record) {
        var confirmMsg = '';

        if (record.groupId === null) {
            confirmMsg = 'You are about to permanently DELETE this note';
        }
        else {
            confirmMsg = 'You are about to permanently DELETE this entire note thread';
        }

        confirm({
            title: confirmMsg,
            content: "Are you sure you would like continue?",
            buttons: ["Yes", "No"],
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                DeleteCustomerNote(record)

                return 0;
            } else {
                return 0;
            }
        });
    }

    function DeleteCustomerNote(record) {
        var confirmMsg = '';

        let postdata = {};
        postdata.itemId = record.itemId;
        postdata.groupId = record.groupId;
        postdata.linkedNotesCount = record.linkedNotesCount;
        postdata.userId = userInfo.userId;

        Axios.post('/api/DeleteCustomerNote', postdata
        ).then(response => {
            let persistentNotesCopy = [...localPersistentNotes];
            persistentNotesCopy = persistentNotesCopy.filter(item => item.itemId !== record.itemId);
            setPersistentNotes(persistentNotesCopy);

            setState({
                ...state,
                dataLoaded: true,
                showNoteDetailsSlider: false
            });


            if (record.linkedNotesCount > 1) {
                confirmMsg = 'Note thread deleted successfully';
            }
            else {
                confirmMsg = 'Note deleted successfully';
            }

            toast.success(confirmMsg);
        }).catch(error => {
            console.log(error);
        });
    }

    function handleUnDeleteNote(e, record) {
        var confirmMsg = '';

        if (record.groupId === null) {
            confirmMsg = 'You are about to RESTORE this note';
        }
        else {
            confirmMsg = 'You are about to RESTORE this entire note thread';
        }

        confirm({
            title: confirmMsg,
            content: "Are you sure you would like continue?",
            buttons: ["Yes", "No"],
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                RestoreNoteThread(record)

                return 0;
            } else {
                return 0;
            }
        });
    }

    function RestoreNoteThread(record) {
        var confirmMsg = '';

        let postdata = {};
        postdata.itemId = record.itemId;
        postdata.groupId = record.groupId;
        postdata.linkedNotesCount = record.linkedNotesCount;
        postdata.userId = userInfo.userId;

        Axios.post('/api/UnDeleteCustomerNote', postdata
        ).then(response => {
            let persistentNotesCopy = [...localPersistentNotes];
            persistentNotesCopy = persistentNotesCopy.filter(item => item.itemId !== record.itemId);
            setPersistentNotes(persistentNotesCopy);

            setState({
                ...state,
                dataLoaded: true,
                showNoteDetailsSlider: false
            });

            if (record.linkedNotesCount > 1) {
                confirmMsg = 'Note thread restored successfully';
            }
            else {
                confirmMsg = 'Note restored successfully';
            }

            toast.success(confirmMsg);
        }).catch(error => {
            console.log(error);
        });
    }

    async function updateTableDisplay(notes, idxToDirectTo = null) {

        //await setState({ ...state, dataLoaded: false });

        var pinnedNoteText;
        var pinnedNoteItemId;
        var pinnedNoteDisplayMode;

        if (notes[idxToDirectTo].itemId === state.pinnedNoteItemId || state.pinnedNoteItemId === null) {
            if (notes[idxToDirectTo].pinned === true) {
                pinnedNoteText = notes[idxToDirectTo].note;
                pinnedNoteItemId = notes[idxToDirectTo].itemId;
                pinnedNoteDisplayMode = undefined;
            }
            else {
                pinnedNoteText = '';
                pinnedNoteItemId = null;
                pinnedNoteDisplayMode = 'none';
            }
        }
        else {
            pinnedNoteText = state.pinnedNoteText;
            pinnedNoteItemId = state.pinnedNoteItemId;
            pinnedNoteDisplayMode = state.pinnedNoteDisplayMode;
        }

        let refreshednotes = [...notes];
        setPersistentNotes(refreshednotes);
        //setfilteredNotes(refreshednotes);

        setState({
            ...state,
            notes: refreshednotes,
            //dataLoaded: true,
            showNoteDetailsSlider: false,
            pinnedNoteText,
            pinnedNoteItemId,
            pinnedNoteDisplayMode
        });
    }

    async function reloadNotesFromDB() {
        getCustomerNotes(props.selectedCustomerId, userInfo.jobShopDivision, userInfo.userId, state.notesFilterType);
    }

    function handleUnpinNote() {
        let postdata = {};
        postdata.requesttype = 'UnPinNote';
        postdata.company_id = props.selectedCustomerId;
        postdata.itemId = state.pinnedNoteItemId;

        Axios.post(`/api/ProcessNotePinRequest`, postdata
        ).then(response => {

            setState({
                ...state,
                pinnedNoteText: '',
                pinnedNoteItemId: null,
                pinnedNoteDisplayMode: 'none'
            });

            var newGroupedNotes = { ...groupedNotes };

            for (const [key, value] of Object.entries(newGroupedNotes)) {
                for (var i = 0, l = value.length; i < l; i++) {
                    value[i].pinned = false;
                }
            }

            setGroupedNotes(newGroupedNotes);

        }).catch(error => {
            console.log(error);
        });
    }

    function searchNotes(value) {
        let searchMatches = [];
        var currNote;
        var valueCleared = false;

        let persistentNotesCopy = [...localPersistentNotes];

        for (let i = 0; i < persistentNotesCopy.length; i++) {
            currNote = persistentNotesCopy[i];

            if (value !== '') {
                if ((currNote.note !== null && currNote.note.toLowerCase().includes(value.toLowerCase())) ||
                    (currNote.salesperson !== null && currNote.salesperson.toLowerCase().includes(value.toLowerCase())) ||
                    (currNote.note_date !== null && Moment(dateWithNoTimezone(currNote.note_date)).format("L") === value)) {

                    searchMatches = [...searchMatches, persistentNotesCopy[i]];
                }
            }
            else {
                valueCleared = true;
            }
        }
        
        setfilteredNotes(valueCleared ? persistentNotesCopy : [...searchMatches]);

        setState({
            ...state,
            searchValue: value
        });
    }

    function searchValueChanged(e) {
        setState({
            ...state,
            searchValue: e.target.value,
            showNoteDetailsSlider: false
        });

        searchNotes(e.target.value);
    }

    const handleDeletedNotesChange = (event) => {
        setShowDeletedNotes(event.target.checked);
    };

    //function InsertSystemNote() {
    //    let postdata = {};
    //    postdata.CustId = props.selectedCustomerId;
    //    postdata.note = getValues().formFields?.note;
    //    postdata.note_date = new Date();
    //    postdata.division = userInfo.jobShopDivision;
    //    postdata.salesperson = null;

    //    Axios.post('/api/InsertCustomerNote', postdata
    //    ).then(async response => {
    //        //hidePane();
    //        //toast.success('Note successfully added');

    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    return (
        <div className="row">
            <Spin spinning={!state.dataLoaded}>
                <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                    <Alert
                        message="Loading Notes"
                        description="Please stand by while we retrieve notes for this customer"
                        type="info"
                    />
                </div>
                {state.dataLoaded &&
                    <Fragment>
                        <div className="row">
                            <div className="parent col-sm-10 col-md-10 col-lg-10" style={{ display: 'flex' }}>
                                <div style={{ whiteSpace: 'nowrap' }}>
                                    <h5 className="frame-heading mt-3">
                                        -- NOTES --
                                    </h5>
                                </div>
                                <div style={{ marginLeft: 20, borderRadius: '10px 10px 10px 10px', overflow: 'hidden', background: '#FADADD', padding: '10px', display: state.pinnedNoteDisplayMode }}>
                                    {state.pinnedNoteText} <button type="button" class="btn btn-link" onClick={handleUnpinNote}>Unpin this note</button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-11 col-md-11 col-lg-12">
                                <Input.Search
                                    style={{ paddingTop: "20px", margin: "0 0 10px 0", width: "50%" }}
                                    placeholder="Search notes by note text, user or date..."
                                    enterButton
                                    onSearch={searchNotes}
                                    allowClear
                                    value={state.searchValue}
                                    onChange={searchValueChanged}
                                />
                            </div>
                            <div className="col-sm-10 col-md-10 col-lg-10">
                            {localPersistentNotes && [...localPersistentNotes].length === 0 && filteredNotes.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                        <span>
                                            No Notes Found For This Customer
                                        </span>
                                    } />
                                    :
                                    <Table className="custom-ant-selection"
                                        rowKey={item => item.itemId}
                                        rowSelection={rowSelection}
                                        hideSelectionColumn={true}
                                        bordered
                                        //dataSource={filteredNotes}
                                        //dataSource={[...localPersistentNotes]}
                                        dataSource={state.searchValue === '' ? [...localPersistentNotes] : filteredNotes}
                                        style={{ whiteSpace: 'pre' }}
                                        columns={columns}
                                        pagination={{
                                            defaultPageSize: 10,
                                            showSizeChanger: true,
                                            pageSizeOptions: ['10', '25', '50', '100'],
                                            showTotal: (total, range) => (
                                                <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                    Showing {range[0]}-{range[1]} of {total}
                                                </span>
                                            )
                                        }}
                                        onRow={(record) => {
                                            return {
                                                onClick: () => {
                                                    let selRows = [record.itemId];
                                                    setSelectedRowKeys([...selRows]);
                                                },
                                                onDoubleClick: (e) => {
                                                    handleEditNote(e, record);
                                                }
                                            }
                                        }}
                                    />
                                }
                            </div>
                            <div className="col-sm-2 col-md-2 col-lg-2 text-left">
                                <div onChange={handleNotesFilterChange}>
                                    <table>
                                        <tbody>
                                            <tr><td><input type="radio" value="allDivisionNotes" name="filterNotes" checked={state.notesFilterType === "allDivisionNotes"} onChange={handleNotesFilterChange} /></td><td>&nbsp;{userInfo.jobShopDivision} Notes</td></tr>
                                            <tr><td><input type="radio" value="myNotes" name="filterNotes" checked={state.notesFilterType === "myNotes"} onChange={handleNotesFilterChange} /></td><td>&nbsp;My Notes</td></tr>
                                            <tr><td><input type="radio" value="allNotes" name="filterNotes" checked={state.notesFilterType === "allNotes"} onChange={handleNotesFilterChange} /></td><td>&nbsp;All Notes</td></tr>
                                            <tr><td><input type="radio" value="systemNotes" name="filterNotes" checked={state.notesFilterType === "systemNotes"} onChange={handleNotesFilterChange} /></td><td>&nbsp;{userInfo.jobShopDivision} System Notes</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div><button className="btn btn-submit mt-4" onClick={handleAddNewNote}>Add New Note</button></div>
                                <p></p>
                                {userInfo.userType === 'admin' && (
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" name="showdeletednotes" checked={showDeletedNotes} onChange={handleDeletedNotesChange} />
                                        <label className="custom-control-label">Show Deleted Notes</label>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Fragment>
                }
            </Spin>
            {state.showNoteDetailsSlider &&
                <NoteDetailsSlider showPane={state.showNoteDetailsSlider} mode={state.noteEditMode} selectedNote={state.selectedNote} notes={filteredNotes} selectedItemId={state.selectedItemId}
                    updateTableDisplay={updateTableDisplay} reloadNotesFromDB={reloadNotesFromDB} selectedCustomerId={props.selectedCustomerId} noteQueue={state.noteQueue} notesDialogDisplayMode={state.notesDialogDisplayMode} />
            }
        </div>
    );
}