import React, { useState, useEffect, useRef } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Axios from '../../config/axios';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Fragment } from 'react';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { useForm, Controller } from "react-hook-form";
import { toast, confirm } from '@rickylandino/react-messages';
import { Select, Drawer } from 'antd';
import Card from 'react-bootstrap/Card';

export default function JST_AdvertiserChangeWFSlider(props) {
    const [state, setState] = useState({
        showPane: false,
        dataLoaded: false,
        showList: [],
        showNames: [],
        searchForm: {
            changeOrder: false,
            changeCompanyInfo: false,
            companyname: "",
            street1: "",
            street2: "",
            city: "",
            state: "",
            zip: "",
            contactid: "",
            contactname: "",
            contacttitle: "",
            contactphone: "",
            contactemail: "",
            materialChange: false,
            sizeChange: false,
            rateChange: false,
            otherChange: false,
            totalcost: "",
            notes: "",
            selectedIssues: ""
        },
        searchFormInitialState: {
            changeOrder: false,
            changeCompanyInfo: false,
            companyname: "",
            street1: "",
            street2: "",
            city: "",
            state: "",
            zip: "",
            contactid: "",
            contactname: "",
            contacttitle: "",
            contactphone: "",
            contactemail: "",
            materialChange: false,
            sizeChange: false,
            rateChange: false,
            otherChange: false,
            totalcost: "",
            notes: "",
            selectedIssues: ""
        },
        issuesListLoaded: false
    });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    useEffect(() => {
        setState({
            ...state,
            issuesListLoaded: false
        });

        GetAllEditorialDeadlines();

    }, [props]);

    useEffect(() => {
        if (state.issuesListLoaded === true) {
            LoadCustomerInfo(props.selectedCustomerId)
        }

    }, [state.issuesListLoaded]);

    function hidePane() {
        setState({
            ...state,
            showPane: false
        });

        setTimeout(() => {
            props.hidePane();
        }, 1000);
    }

    function GetAllEditorialDeadlines() {
        let postdata = {};

        Axios.post(`/api/GetFutureIssueList/`, postdata
        ).then(response => {
            const issueList = response.data;

            let futureIssues = [];

            console.log(issueList);

            for (var i = 0, l = issueList.length; i < l; i++) {
                var issue = issueList[i];

                const futureissue = {
                    selected: '', issue: issue.issue, description: issue.description, issueorshowprogram: issue.issueorshowprogram, showcode: issue.showcode, showdate: issue.showdate, invoiced: issue.invoiced
                };

                console.log(futureissue);

                futureIssues.push(futureissue);
            }

            setState({
                ...state,
                dataLoaded: true,
                issueList,
                futureIssues,
                showPane: props.showPane,
                issuesListLoaded: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    //function GetFutureShowList() {
    //    Axios.get(`/api/GetFutureShowList/`
    //    ).then(response => {
    //        const showList = response.data;

    //        let futureShows = [];

    //        for (var i = 0, l = showList.length; i < l; i++) {
    //            var show = showList[i];

    //            const futureshow = {
    //                selected: '', name: show.name, city: show.city, startdate: show.startdate, finishdate: show.finishdate, showcode: show.showcode
    //            };
    //            futureShows.push(futureshow);
    //        }

    //        setState({
    //            ...state,
    //            dataLoaded: true,
    //            showList,
    //            futureShows,
    //            showPane: props.showPane,
    //            showsListLoaded: true
    //        });
    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    function LoadCustomerInfo(selectedCustomerId) {
        let postdata = {};
        let myContactName = '';
        let myContactTitle = '';
        let myContactPhone = '';
        let myContactEmail = '';

        postdata.company_id = selectedCustomerId;
        postdata.jobshopdivision = "JSS";

        Axios.post(`/api/GetCustomerById`, postdata
        ).then(response => {

            //Init Customer fields 
            let customer = response.data.customer;
            console.log(customer);

            let contacts = response.data.contacts;
            console.log(contacts);

            for (var i = 0, l = contacts.length; i < l; i++) {
                var contact = contacts[i];

                if (contact.contact_type === 'Main') {
                    myContactName = contact.jsS_name;
                    myContactTitle = contact.title;
                    myContactPhone = contact.phone;
                    myContactEmail = contact.email;

                    break;
                }
            }

            setState({
                ...state,
                searchForm: {
                    ...state.searchForm,
                    changeOrder: false,
                    changeCompanyInfo: false,
                    companyname: customer.name,
                    street1: customer.street1,
                    city: customer.city,
                    state: customer.state,
                    zip: customer.zip,
                    contactname: myContactName,
                    contacttitle: myContactTitle,
                    contactphone: myContactPhone,
                    contactemail: myContactEmail,
                    materialChange: false,
                    sizeChange: false,
                    rateChange: false,
                    otherChange: false,
                    totalcost: "",
                    notes: "",
                    selectedIssues: ""
                },
                searchFormInitialState: {
                    ...state.searchFormInitialState,
                    changeOrder: false,
                    changeCompanyInfo: false,
                    companyname: customer.name,
                    street1: customer.street1,
                    city: customer.city,
                    state: customer.state,
                    zip: customer.zip,
                    contactname: myContactName,
                    contacttitle: myContactTitle,
                    contactphone: myContactPhone,
                    contactemail: myContactEmail,
                    materialChange: false,
                    sizeChange: false,
                    rateChange: false,
                    otherChange: false,
                    totalcost: "",
                    notes: "",
                    selectedIssues: ""
                },
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function handleSubmitWorkflow() {
        let selectedIssues = '';

        console.log(state.futureIssues);

        for (var i = 0, l = state.futureIssues.length; i < l; i++) {
            var issue = state.futureIssues[i];

            if (issue.selected === 'Yes') {
                selectedIssues += "|" + issue.description;
            }
        }
        if (selectedIssues !== '') {
            selectedIssues += "|"
        }

        let workflowform = {};
        workflowform.searchForm = state.searchForm;
        workflowform.searchForm.selectedIssues = selectedIssues;
        workflowform.searchFormInitialState = state.searchFormInitialState;
        workflowform.searchFormInitialState.selectedIssues = '';

        console.log(workflowform.searchForm);

        let postdata = {
            submittedby: userInfo.userName,
            company_id: props.selectedCustomerId,
            company: props.selectedCustomerName,
            issuesList: state.futureIssues,
            workflowform: JSON.stringify(workflowform)
        }

        console.log(postdata);

        Axios.post(`/api/SendAdvertiserChangeRequestNotificationEmail`, postdata
        ).then(response => {
            if (response.data === true) {
                hidePane();
                toast.success("Advertiser Change Request Processed Successfully");
            }
            else {
                toast.error("Error Processing Advertiser Change Request");
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        //console.log(name);

        setState({
            ...state,
            searchForm: { ...state.searchForm, [name]: value }
        });
    }

    const handleIssueSelectedChange = (selectedIssue) => {
        let futureIssues = [...state.futureIssues];
        var objectToChangeIdx = futureIssues.findIndex(issue => issue.issue === selectedIssue);
        var objectChanged = { ...futureIssues[objectToChangeIdx] };

        //toggles the selection
        if (objectChanged.selected === '') {
            objectChanged.selected = 'Yes';
        }
        else {
            objectChanged.selected = '';
        }

        futureIssues[objectToChangeIdx] = objectChanged;

        setState({
            ...state,
            futureIssues
        });
    }

    return (
        <Drawer
            closeIcon={<i className="fas fa-chevron-right float-left"></i>}
            title='Advertiser Change Request'
            placement={'right'}
            onClose={hidePane}
            visible={state.showPane}
            key='advertiserChangeRequest'
            width={'85%'}

            footer={
                <div className="form-group col-12 padding-25-10">
                    <button id="btnSaveChanges" onClick={handleSubmitWorkflow} className="btn btn-submit me-3" style={{ 'marginLeft': '5px' }}>Submit Workflow</button>
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            }
        >
            <div className="slide-pane-body">
                <div className="row mb-3">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <Card className="d2pCard">
                            <Card.Header><span>Order Type</span></Card.Header>
                            <Card.Body>
                                <div style={{ marginRight: '20px', float: 'left' }}><input type="checkbox" name="changeOrder" checked={state.searchForm.changeOrder} onChange={handleInputChange} />&nbsp;Change Order</div>
                                <div style={{ marginRight: '20px', float: 'left' }}><input type="checkbox" name="changeCompanyInfo" checked={state.searchForm.changeCompanyInfo} onChange={handleInputChange} />&nbsp;Change Company Info</div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-12 col-md-12 col-lg-8">
                        <Card className="d2pCard mb-3">
                            <Card.Header><span>Company Information</span></Card.Header>
                            <Card.Body>
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <label className="form-label">Company</label>
                                        <input className="form-control-custom" type="text" name="companyname" placeholder="" value={state.searchForm.companyname} onChange={handleInputChange} readOnly />
                                    </div>

                                    <div className="form-group col-lg-8">
                                        <label className="form-label">Address</label>
                                        <input className="form-control-custom" type="text" name="street1" placeholder="" value={state.searchForm.street1} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">P.O. Box</label>
                                        <input className="form-control-custom" type="text" name="street2" placeholder="" value={state.searchForm.street2} onChange={handleInputChange} />
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label className="form-label">City</label>
                                        <input className="form-control-custom" type="text" name="city" placeholder="" value={state.searchForm.city} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">State</label>
                                        <input className="form-control-custom" type="text" name="state" placeholder="" value={state.searchForm.state} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Zip</label>
                                        <input className="form-control-custom" type="text" name="zip" placeholder="" value={state.searchForm.zip} onChange={handleInputChange} />
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label className="form-label">Contact</label>
                                        <input className="form-control-custom" type="text" name="contactname" placeholder="" value={state.searchForm.contactname} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label className="form-label">Title</label>
                                        <input className="form-control-custom" type="text" name="contacttitle" placeholder="" value={state.searchForm.contacttitle} onChange={handleInputChange} />
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label className="form-label">Phone</label>
                                        <input className="form-control-custom" type="text" name="contactphone" placeholder="" value={state.searchForm.contactphone} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label className="form-label">Email</label>
                                        <input className="form-control-custom" type="text" name="contactemail" placeholder="" value={state.searchForm.contactemail} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card className="d2pCard mb-3">
                            <Card.Header><span>Order Details</span></Card.Header>
                            <Card.Body>
                                <div style={{ marginRight: '20px', float: 'left' }}><input type="checkbox" name="materialChange" checked={state.searchForm.materialChange} onChange={handleInputChange} />&nbsp;Material Change</div>
                                <div style={{ marginRight: '20px', float: 'left' }}><input type="checkbox" name="sizeChange" checked={state.searchForm.sizeChange} onChange={handleInputChange} />&nbsp;Size Change</div>
                                <div style={{ marginRight: '20px', float: 'left' }}><input type="checkbox" name="rateChange" checked={state.searchForm.rateChange} onChange={handleInputChange} />&nbsp;Rate Change</div>
                                <div style={{ marginRight: '20px', float: 'left' }}><input type="checkbox" name="otherChange" checked={state.searchForm.otherChange} onChange={handleInputChange} />&nbsp;Other Change</div>

                                <div className="row">
                                    <label>
                                        Total Cost:&nbsp;<input className="inline-text-box" type="text" name="totalcost" placeholder="" value={state.searchForm.totalcost} onChange={handleInputChange} />
                                    </label>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card className="d2pCard">
                            <Card.Header><span>Notes</span></Card.Header>
                            <Card.Body>
                                <textarea style={{ width: '100%', height: '200px' }} name="notes" placeholder="" value={state.searchForm.notes} onChange={handleInputChange} />
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-xs-12 col-md-12 col-lg-4">
                        <Card className="d2pCard">
                            <Card.Header><span>Upcoming Issues</span></Card.Header>
                            <Card.Body>
                                {state.futureIssues &&
                                    state.futureIssues.map((issue, index) => (
                                        <div style={{ "paddingBottom": "10px" }} className="row">
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                {/*<input type="checkbox" name={`leadretrieval${show.name}`} onChange={() => handleOptionChange('leadretrieval', show.name)} checked={show.leadretrieval === 'Yes'} /> Lead Retrieval*/}
                                                <input type="checkbox" checked={issue.selected === 'Yes'} name={issue.issue} onChange={() => handleIssueSelectedChange(issue.issue)} />&nbsp;{issue.issue}: {issue.description}
                                            </div>
                                        </div>
                                    ))
                                }
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-12 col-md-12 col-lg-8">
                        
                    </div>
                    {/*<div className="col-xs-12 col-md-12 col-lg-4">*/}
                    {/*    <Card className="d2pCard">*/}
                    {/*        <Card.Header><span>Packages</span></Card.Header>*/}
                    {/*        <Card.Body>*/}
                    {/*            <div><input type="checkbox" name="leadretrieval" checked={state.searchForm.leadretrieval} onChange={handleInputChange} />&nbsp;Lead Retrieval</div>*/}
                    {/*            <div><input type="checkbox" name="internetlink" checked={state.searchForm.internetlink} onChange={handleInputChange} />&nbsp;Internet Link</div>*/}
                    {/*            <div><input type="checkbox" name="qualifiedprospectlist" checked={state.searchForm.qualifiedprospectlist} onChange={handleInputChange} />&nbsp;Qualified Prospect List</div>*/}

                    {/*            <div className="form-group col-lg-6">*/}
                    {/*                <label className="form-label">Total Cost</label>*/}
                    {/*                <input className="form-control-custom" type="text" name="totalcost" placeholder="" value={state.searchForm.totalcost} onChange={handleInputChange} />*/}
                    {/*            </div>*/}
                    {/*        </Card.Body>*/}
                    {/*    </Card>*/}
                    {/*</div>*/}
                </div>
                <div className="row mb-3">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        
                    </div>
                </div>
            </div>
        </Drawer>
    );
}