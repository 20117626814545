import React, { useState, useEffect } from 'react';
import { Avatar, Button, Comment, Form, Input, List } from 'antd';
import moment from 'moment';
import { useForm, Controller } from "react-hook-form";
import { dateWithNoTimezone, localDateTime } from '../Helpers/DateFormat';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import ContentEditable from "react-contenteditable";
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import { insertCustomerNoteBetter, processNotePinRequest, updateCustomerNote } from '../../services/NotesService';
import parse from 'html-react-parser';
import {
    PushpinOutlined,
    PushpinFilled,
    DeleteOutlined,
    EditOutlined
} from '@ant-design/icons';

const { TextArea } = Input;
const CommentList = ({ comments, handlePinnedChangeFromQueue, handleDeleteNote, handleEditNote }) => (
    <List
        dataSource={comments}
        itemLayout="horizontal"
        renderItem={(props) =>
            <>
                <div className="d-flex">
                    {(props.author === props.salesperson || props.viewOnly === false) && props.notesDialogDisplayMode === 'Editable' ?
                        <>
                            <EditOutlined className="d-flex align-items-center me-3 hover" style={{ fontSize: '1.0rem' }} onClick={() => handleEditNote(props.itemid)} />&nbsp;
                            <DeleteOutlined className="d-flex align-items-center me-3 hover" style={{ fontSize: '1.5rem', color: 'red' }} onClick={() => handleDeleteNote(props.itemid, props.groupid)} />
                        </>
                        : null
                    }&nbsp;
                    {/*{(props.author === props.salesperson || props.viewOnly === false) && props.notesDialogDisplayMode === 'Editable' ? <DeleteOutlined className="d-flex align-items-center me-3 hover" style={{ fontSize: '1.5rem', color: 'red' }} onClick={() => handleDeleteNote(props.itemid, props.groupid)} /> : null}&nbsp;*/}
                    {props.pinned && props.notesDialogDisplayMode === 'Editable' ? <PushpinFilled className="d-flex align-items-center me-3 hover" style={{ fontSize: '1.5rem', color: '#275282' }} /> : props.notesDialogDisplayMode === 'Editable' ? <PushpinOutlined onClick={() => handlePinnedChangeFromQueue(props.custid, props.itemid)} className="d-flex align-items-center me-3 hover" style={{ fontSize: '1.5rem', color: '#275282' }} /> : null}
                    <Comment {...props} />
                </div>
            </>
        }
    />
);
const Editor = ({ onChange, onSubmit, submitting, value }) => (
    <>
        <Form.Item>
            <TextArea rows={4} onChange={onChange} value={value} />
        </Form.Item>
        <Form.Item>
            <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
                Add Comment
            </Button>
        </Form.Item>
    </>
);

function isHTML(input) {
    return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input);
}

export default function NotesDialog(props) {
    const [comments, setComments] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    //const [value, setValue] = useState('');
    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [viewOnly, setViewOnly] = useState(userInfo.userType === 'admin' ? false : true);
    const [selectedNoteForEditing, setSelectedNoteForEditing] = useState(null);

    const [state, setState] = useState({

    });

    useEffect(() => {
        var modifiedQueue = [...props.noteQueue].map(item => ({
            author: item.salesperson,
            content: <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: item.note }} />,
            //datetime: 'on ' + moment.utc(item.note_date).format("MM/DD/YYYY hh:mm a"),
            datetime: 'on ' + moment.utc(item.note_date).format("MM/DD/YYYY hh:mm a").replace(" 12:00 am", ""), //ignores time for older legacy notes where no time was being tracked
            groupid: item.groupId,
            pinned: item.pinned,
            itemid: item.itemId,
            custid: item.custId,
            salesperson: userInfo.userId,
            viewOnly,
            notesDialogDisplayMode: props.notesDialogDisplayMode
        }));

        setComments(modifiedQueue);
    }, []);

    const handleSubmit = () => {
        if (selectedDate === null) {
            toast.error('A note date is required to save');
            return;
        }

        if (getValues().formFields.note.trim() === '') {
            toast.error('A note is required to save');
            return;
        }

        if (selectedNoteForEditing !== null) {
            let postdata = selectedNoteForEditing;

            postdata.note = getValues().formFields.note;
            postdata.note_date = new Date(selectedDate);
            postdata.pinned = getValues().formFields.pinned;

            updateCustomerNote(postdata).then(data => {
                props.hidePane();
            });
        }
        else {
            let postdata = {
                custId: props.selectedCustomerId,
                note: getValues().formFields.note,
                note_date: new Date(selectedDate),
                division: userInfo.jobShopDivision,
                salesperson: userInfo.userId,
                pinned: getValues().formFields.pinned,
                groupId: comments.length > 0 ? comments[0].groupid : null,
                linkedNotes: props.noteQueue.map(n => n.itemId)
            }

            insertCustomerNoteBetter(postdata).then(data => {
                props.hidePane();
            });
        }
    };
    async function checkForPinnedNote(company_id, itemId) {
        const response = await Axios.get(`/api/CheckForPinnedNote`, {
            params: {
                company_id,
                itemId
            }
        });

        return response.data;
    }

    function handlePinnedChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        Axios.get(`/api/CheckForPinnedNote`, {
            params: {
                company_id: props.selectedCustomerId,
                itemId: props.selectedItemId,
            }
        }).then(response => {
            if (response.data === true) {
                if (userInfo.jobShopDivision === 'JSS') {
                    toast.error('Another note is already pinned for this exhibitor - only 1 is allowed');
                }
                else {
                    toast.error('Another note is already pinned for this advertiser - only 1 is allowed');
                }
                return;
            }
            else {
                setValue('formFields.pinned', value);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    async function handlePinnedChangeFromQueue(company_id, itemId) {
        const hasPinnedNote = await checkForPinnedNote(company_id, itemId);

        if (hasPinnedNote) {
            if (userInfo.jobShopDivision === 'JSS') {
                toast.error('Another note is already pinned for this exhibitor - only 1 is allowed');
            }
            else {
                toast.error('Another note is already pinned for this advertiser - only 1 is allowed');
            }
        } else {

            var postdata = {
                requesttype: 'PinNote',
                company_id,
                itemId
            }

            //set comment as pinned
            processNotePinRequest(postdata).then(data => {
                if (data) {
                    var newComments = [...comments];
                    var commentIdx = newComments.findIndex(c => c.itemid === itemId);
                    let comment = newComments[commentIdx];
                    comment.pinned = true;
                    newComments[commentIdx] = comment;
                    setComments(newComments);
                }
            });
        }
    }

    async function handleDeleteNote(itemId, groupid) {
        var confirmMsg = 'You are about to permanently DELETE this note';

        confirm({
            title: confirmMsg,
            content: "Are you sure you would like continue?",
            buttons: ["Yes", "No"],
        }, (buttonPressed) => {
            if (buttonPressed === 'Yes') {
                var confirmMsg = '';

                let postdata = {};
                postdata.itemId = itemId;
                postdata.groupId = groupid;         // the API does not really need the groupid here since it will only delete the single note
                postdata.linkedNotesCount = 0;      //setting to 0 here so the API only deletes the single note in the thread
                postdata.userId = userInfo.userId;

                Axios.post('/api/DeleteCustomerNote', postdata
                ).then(response => {
                    props.hidePane();

                    toast.success('Note deleted successfully');
                }).catch(error => {
                    console.log(error);
                });

                return 0;
            } else {
                return 0;
            }
        });
    }

    async function handleEditNote(itemId) {
        console.log(itemId);

        Axios.get(`/api/GetSingleNote`, {
            params: {
                itemId: itemId,
            }
        }).then(response => {
            let note = response.data;

            console.log(response.data);

            setValue('formFields.note', note.note);
            setSelectedDate(new Date(note.note_date));

            setSelectedNoteForEditing(note);

            setState({
                ...state,
            });

        }).catch(error => {
            console.log(error);
        });
    }

    function handleClearFields() {
        setValue('formFields.note', null);
        setSelectedDate(new Date());
        setSelectedNoteForEditing(null);

        setState({
            ...state,
        });
    }

    return (
        <div className="row h-100">
            {props.noteQueue.length > 0 &&
                <div className="col col-lg-6 h-100" style={{ overflow: 'auto' }}>
                {comments.length > 0 && <CommentList comments={comments} handlePinnedChangeFromQueue={handlePinnedChangeFromQueue} handleDeleteNote={handleDeleteNote} handleEditNote={handleEditNote} />}
                </div>
            }
            <div className="col">
            <Comment
                content={
                    <div style={props.notesDialogDisplayMode === 'Editable' ? {} : { display: 'none' }}>
                        <div className="row">
                            <div className="form-group col-lg-2">
                                    <label className="form-label">Note Date</label>
                                <DatePicker className="form-control-custom" style={{ zIndex: 10000 }}
                                    selected={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                    dateFormat="MM/dd/yyyy" // Adjust as needed
                                />
                            </div>
                            <div className="form-group col-lg-10"></div>

                            <div className="form-group col-lg-12">
                                    <label className="form-label">{props.noteQueue.length > 0 ? 'Add Follow Up Note' : 'Note'}</label>
                                {isHTML(getValues().formFields?.note) ?
                                    <ContentEditable
                                        html={getValues().formFields.note} // innerHTML of the editable div
                                        disabled={false} // use true to disable edition
                                        onChange={(e) => setValue('formFields.note', e.target.value)} // handle innerHTML change
                                        style={{ border: '2px solid rgba(0, 0, 0, 0.05)', padding: 10 }}
                                    />
                                    :
                                    <textarea name="description" cols="50" rows="20" {...register("formFields.note")} className="form-control-custom" />
                                }
                            </div>

                            {/*<div className="form-group col-lg-12">*/}
                            {/*    <div className="custom-control custom-checkbox ms-3">*/}
                            {/*        <input type="checkbox" className="custom-control-input" name="pinned" {...register("formFields.pinned")} checked={getValues().formFields?.pinned} onChange={handlePinnedChange} />*/}
                            {/*        <label className="custom-control-label">Pin This Note</label>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                            
                        <p>&nbsp;&nbsp;</p>
                        <div className="row">
                            <div className="form-group col-lg-4">
                                    <button className="btn btn-submit" onClick={handleSubmit}>{selectedNoteForEditing === null ? 'Add Note' : 'Update Note'}</button>&nbsp;&nbsp;&nbsp;
                                <button className="btn btn-link" onClick={handleClearFields}>Clear Fields</button>
                            </div>
                            <div className="form-group col-lg-8">
                                <div className="custom-control custom-checkbox ms-3">
                                    <input type="checkbox" className="custom-control-input" name="pinned" {...register("formFields.pinned")} checked={getValues().formFields?.pinned} onChange={handlePinnedChange} />
                                    <label className="custom-control-label">Pin This Note</label>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                />
            </div>
        </div>
    );
}