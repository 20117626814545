import React, { useState, useEffect, useRef } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Axios from '../../config/axios';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import { Fragment } from 'react';
import Moment from 'moment';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { useForm, Controller } from "react-hook-form";
import { toast, confirm } from '@rickylandino/react-messages';
import { Select, Drawer } from 'antd';
import Card from 'react-bootstrap/Card';
import { insertNewWorklistItem } from '../../services/WorklistService';
import { ApproveDenyOrRequestMoreInfoButton } from '../Dashboard/Buttons/ApproveDenyOrRequestMoreInfoButton';

export default function JSS_ExhibitorChangeWFSlider(props) {
    const [state, setState] = useState({
        showPane: false,
        dataLoaded: false,
        showList: [],
        showNames: [],
        searchForm: {
            newsale: false,
            rebill: false,
            boothmove: false,
            cancellation: false,
            change: false,
            companyname: "",
            street1: "",
            street2: "",
            city: "",
            state: "",
            zip: "",
            contactid: "",
            contactname: "",
            contacttitle: "",
            contactphone: "",
            contactemail: "",
            newsalebooth1: "",
            newsalebooth2: "",
            boothmovefrom: "",
            boothmoveto: "",
            cancelby: "",
            cancelboothnumber: "",
            leadretrieval: false,
            internetlink: false,
            qualifiedprospectlist: false,
            totalcost: "",
            notes: ""
        },
        searchFormInitialState: {
            newsale: false,
            rebill: false,
            boothmove: false,
            cancellation: false,
            change: false,
            companyname: "",
            street1: "",
            street2: "",
            city: "",
            state: "",
            zip: "",
            contactid: "",
            contactname: "",
            contacttitle: "",
            contactphone: "",
            contactemail: "",
            newsalebooth1: "",
            newsalebooth2: "",
            boothmovefrom: "",
            boothmoveto: "",
            cancelby: "",
            cancelboothnumber: "",
            leadretrieval: false,
            internetlink: false,
            qualifiedprospectlist: false,
            totalcost: "",
            notes: ""
        },
        showsListLoaded: false
    });

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    useEffect(() => {
        setState({
            ...state,
            showsListLoaded: false
        });

        GetFutureShowList();

    }, [props]);

    useEffect(() => {
        console.log(props);

        if (state.showsListLoaded === true) {
            LoadCustomerInfo(props.selectedCustomerId)
        }

    }, [state.showsListLoaded]);

    function hidePane() {
        setState({
            ...state,
            showPane: false
        });

        setTimeout(() => {
            props.hidePane();
        }, 1000);
    }

    function GetFutureShowList() {
        Axios.get(`/api/GetFutureShowList/`
        ).then(response => {
            const showList = response.data;

            let futureShows = [];

            for (var i = 0, l = showList.length; i < l; i++) {
                var show = showList[i];

                const futureshow = {
                    selected: '', name: show.name, city: show.city, startdate: show.startdate, finishdate: show.finishdate, showcode: show.showcode
                };
                futureShows.push(futureshow);
            }

            setState({
                ...state,
                dataLoaded: true,
                showList,
                futureShows,
                showPane: props.showPane,
                showsListLoaded: true
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function LoadCustomerInfo(selectedCustomerId) {
        let postdata = {};
        let myContactName = '';
        let myContactTitle = '';
        let myContactPhone = '';
        let myContactEmail = '';

        postdata.company_id = selectedCustomerId;
        postdata.jobshopdivision = "JSS";

        Axios.post(`/api/GetCustomerById`, postdata
        ).then(response => {

            //Init Customer fields 
            let customer = response.data.customer;
            console.log(customer);

            let contacts = response.data.contacts;
            console.log(contacts);

            for (var i = 0, l = contacts.length; i < l; i++) {
                var contact = contacts[i];

                if (contact.contact_type === 'Main') {
                    myContactName = contact.jsS_name;
                    myContactTitle = contact.title;
                    myContactPhone = contact.phone;
                    myContactEmail = contact.email;

                    break;
                }
            }

            setState({
                ...state,
                searchForm: {
                    ...state.searchForm,
                    companyname: customer.name,
                    street1: customer.street1,
                    city: customer.city,
                    state: customer.state,
                    zip: customer.zip,
                    contactname: myContactName,
                    contacttitle: myContactTitle,
                    contactphone: myContactPhone,
                    contactemail: myContactEmail,
                    newsale: false,
                    rebill: false,
                    boothmove: false,
                    cancellation: false,
                    change: false,
                    newsalebooth1: "",
                    newsalebooth2: "",
                    boothmovefrom: "",
                    boothmoveto: "",
                    cancelby: "",
                    cancelboothnumber: "",
                    leadretrieval: false,
                    internetlink: false,
                    qualifiedprospectlist: false,
                    totalcost: "",
                    notes: "",
                    selectedShows: ""
                },
                searchFormInitialState: {
                    ...state.searchFormInitialState,
                    companyname: customer.name,
                    street1: customer.street1,
                    city: customer.city,
                    state: customer.state,
                    zip: customer.zip,
                    contactname: myContactName,
                    contacttitle: myContactTitle,
                    contactphone: myContactPhone,
                    contactemail: myContactEmail,
                    newsale: false,
                    rebill: false,
                    boothmove: false,
                    cancellation: false,
                    change: false,
                    newsalebooth1: "",
                    newsalebooth2: "",
                    boothmovefrom: "",
                    boothmoveto: "",
                    cancelby: "",
                    cancelboothnumber: "",
                    leadretrieval: false,
                    internetlink: false,
                    qualifiedprospectlist: false,
                    totalcost: "",
                    notes: "",
                    selectedShows: ""
                },
            });
        }).catch(error => {
            console.log(error);
        });
    }

    function handleSubmitRealWorkflow() {
        let selectedShows = '';

        console.log(state.futureShows);

        for (var i = 0, l = state.futureShows.length; i < l; i++) {
            var show = state.futureShows[i];

            if (show.selected === 'Yes') {
                selectedShows += "|" + show.name;
            }
        }
        if (selectedShows !== '') {
            selectedShows += "|"
        }


        let wfStateInitial = {};
        wfStateInitial.searchFormInitialState = state.searchFormInitialState;
        wfStateInitial.searchFormInitialState.selectedShows = '';

        let wfState = {};
        wfState.searchForm = state.searchForm;
        wfState.searchForm.selectedShows = selectedShows;


        ////old way
        //let workflowform = {};
        //workflowform.searchForm = state.searchForm;
        //workflowform.searchForm.selectedShows = selectedShows;

        //workflowform.searchFormInitialState = state.searchFormInitialState;
        //workflowform.searchFormInitialState.selectedShows = '';

        let postdata = {
            originatorUsername: userInfo.userId,
            company_Id: props.selectedCustomerId,
            itemDetail: "Process JSS Exhibitor Change Request",
            oldValue: JSON.stringify(wfStateInitial),
            newValue: JSON.stringify(wfState)
        }

        insertNewWorklistItem(postdata).then(data => {
            if (data) {
                toast.success("Worklist item added successfully");

                setState({
                    ...state,
                    showPane: false
                });
            } else {
                toast.error("Something went wrong");
            }
        }).catch(error => {
            toast.error("Something went wrong");
        });   
    }

    function handleSubmitWorkflow() {
        let selectedShows = '';

        console.log(state.futureShows);

        for (var i = 0, l = state.futureShows.length; i < l; i++) {
            var show = state.futureShows[i];

            if (show.selected === 'Yes') {
                selectedShows += "|" + show.name;
            }
        }
        if (selectedShows !== '') {
            selectedShows += "|"
        }

        let workflowform = {};
        workflowform.searchForm = state.searchForm;
        workflowform.searchForm.selectedShows = selectedShows;
        workflowform.searchFormInitialState = state.searchFormInitialState;
        workflowform.searchFormInitialState.selectedShows = '';

        let postdata = {
            submittedby: userInfo.userName,
            company_id: props.selectedCustomerId,
            company: props.selectedCustomerName,
            showsList: state.futureShows,
            workflowform: JSON.stringify(workflowform)
        }

        Axios.post(`/api/SendExhibitorChangeRequestNotificationEmail`, postdata
        ).then(response => {
            if (response.data === true) {
                hidePane();
                toast.success("Exhibitor Change Request Processed Successfully");
            }
            else {
                toast.error("Error Processing Exhibitor Change Request");
            }
        }).catch(error => {
            console.log(error);
        });
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        console.log(name);

        if (name.includes("showname")) {
            var showList = state.showList;
            for (var i = 0, l = showList.length; i < l; i++) {
                if (showList[i].formkey == name) {
                    showList[i].formstatus = value;

                    setState({
                        ...state,
                        showList
                    });

                    break;
                }
            }
        }
        else {
            setState({
                ...state,
                searchForm: { ...state.searchForm, [name]: value }
            });
        }    
    }

    const handleShowSelectedChange = (showname) => {
        let futureShows = [...state.futureShows];
        var objectToChangeIdx = futureShows.findIndex(show => show.name === showname);
        var objectChanged = { ...futureShows[objectToChangeIdx] };

        //toggles the selection
        if (objectChanged.selected === '') {
            objectChanged.selected = 'Yes';
        }
        else {
            objectChanged.selected = '';
        }

        futureShows[objectToChangeIdx] = objectChanged;

        setState({
            ...state,
            futureShows
        });
    }

    return (
        <Drawer
            closeIcon={<i className="fas fa-chevron-right float-left"></i>}
            title='Exhibitor Change Request'
            placement={'right'}
            onClose={hidePane}
            visible={state.showPane}
            key='exhibitorChangeRequest'
            width={'85%'}

            footer={
                <div className="form-group col-12 padding-25-10">
                    <button id="btnSaveChanges" onClick={handleSubmitRealWorkflow} className="btn btn-submit me-3" style={{ 'marginLeft': '5px' }}>Submit Workflow</button>
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            }
        >
            <div className="slide-pane-body">
                <div className="row mb-3">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <Card className="d2pCard">
                            <Card.Header><span>Order Type</span></Card.Header>
                            <Card.Body>
                                <div style={{ marginRight: '20px', float: 'left' }}><input type="checkbox" name="newsale" checked={state.searchForm.newsale} onChange={handleInputChange} />&nbsp;New&nbsp;Sale</div>
                                <div style={{ marginRight: '20px', float: 'left' }}><input type="checkbox" name="rebill" checked={state.searchForm.rebill} onChange={handleInputChange} />&nbsp;Rebill</div>
                                <div style={{ marginRight: '20px', float: 'left' }}><input type="checkbox" name="boothmove" checked={state.searchForm.boothmove} onChange={handleInputChange} />&nbsp;Booth&nbsp;Move</div>
                                <div style={{ marginRight: '20px', float: 'left' }}><input type="checkbox" name="cancellation" checked={state.searchForm.cancellation} onChange={handleInputChange} />&nbsp;Cancellation</div>
                                <div style={{ marginRight: '20px', float: 'none' }}><input type="checkbox" name="change" checked={state.searchForm.change} onChange={handleInputChange} />&nbsp;Change</div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-12 col-md-12 col-lg-8">
                        <Card className="d2pCard">
                            <Card.Header><span>Company Information</span></Card.Header>
                            <Card.Body>
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <label className="form-label">Company</label>
                                        <input className="form-control-custom" type="text" name="companyname" placeholder="" value={state.searchForm.companyname} onChange={handleInputChange} readOnly />
                                    </div>

                                    <div className="form-group col-lg-8">
                                        <label className="form-label">Address</label>
                                        <input className="form-control-custom" type="text" name="street1" placeholder="" value={state.searchForm.street1} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">P.O. Box</label>
                                        <input className="form-control-custom" type="text" name="street2" placeholder="" value={state.searchForm.street2} onChange={handleInputChange} />
                                    </div>

                                    <div className="form-group col-lg-4">
                                        <label className="form-label">City</label>
                                        <input className="form-control-custom" type="text" name="city" placeholder="" value={state.searchForm.city} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">State</label>
                                        <input className="form-control-custom" type="text" name="state" placeholder="" value={state.searchForm.state} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Zip</label>
                                        <input className="form-control-custom" type="text" name="zip" placeholder="" value={state.searchForm.zip} onChange={handleInputChange} />
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label className="form-label">Contact</label>
                                        <input className="form-control-custom" type="text" name="contactname" placeholder="" value={state.searchForm.contactname} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label className="form-label">Title</label>
                                        <input className="form-control-custom" type="text" name="contacttitle" placeholder="" value={state.searchForm.contacttitle} onChange={handleInputChange} />
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label className="form-label">Phone</label>
                                        <input className="form-control-custom" type="text" name="contactphone" placeholder="" value={state.searchForm.contactphone} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label className="form-label">Email</label>
                                        <input className="form-control-custom" type="text" name="contactemail" placeholder="" value={state.searchForm.contactemail} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-xs-12 col-md-12 col-lg-4">
                        <Card className="d2pCard">
                            <Card.Header><span>Upcoming Shows</span></Card.Header>
                            <Card.Body>
                                {state.futureShows &&
                                    state.futureShows.map((show, index) => (
                                        <div style={{ "paddingBottom": "10px" }} className="row">
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                {/*<input type="checkbox" name={`leadretrieval${show.name}`} onChange={() => handleOptionChange('leadretrieval', show.name)} checked={show.leadretrieval === 'Yes'} /> Lead Retrieval*/}
                                                <input type="checkbox" checked={show.selected === 'Yes'} name={show.name} onChange={() => handleShowSelectedChange(show.name)} />&nbsp;{show.name}
                                            </div>
                                        </div>
                                    ))
                                }

                                {/*{state.showNames &&*/}
                                {/*    Object.keys(state.showNames).map((key, index) => (*/}
                                {/*        <div style={{ "paddingBottom": "4px" }} className="row">*/}
                                {/*            <div className="col-sm-12 col-md-12 col-lg-12">*/}
                                {/*                <input type="checkbox" checked={state.searchForm[key]} name="show0" onChange={handleInputChange} />&nbsp;{state.showNames[key]}*/}
                                {/*            </div>*/}
                                {/*        </div>  */}
                                {/*    ))*/}
                                {/*}*/}
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-12 col-md-12 col-lg-8">
                        <Card className="d2pCard">
                            <Card.Header><span>Order Details</span></Card.Header>
                            <Card.Body>
                                <div className="row">
                                    <div className="form-group col-lg-6">
                                        <label className="form-label">New Sale Booth Assignment</label>
                                    </div>
                                    <div className="form-group col-lg-3">
                                        <input type="text" name="newsalebooth1" placeholder="Booth 1" value={state.searchForm.newsalebooth1} onChange={handleInputChange} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-3">
                                        <input type="text" name="newsalebooth2" placeholder="Booth 2" value={state.searchForm.newsalebooth2} onChange={handleInputChange} className="form-control-custom" />
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label className="form-label">Booth Move Details</label>
                                    </div>
                                    <div className="form-group col-lg-3">
                                        <input type="text" name="boothmovefrom" placeholder="From" value={state.searchForm.boothmovefrom} onChange={handleInputChange} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-3">
                                        <input type="text" name="boothmoveto" placeholder="To" value={state.searchForm.boothmoveto} onChange={handleInputChange} className="form-control-custom" />
                                    </div>

                                    <div className="form-group col-lg-6">
                                        <label className="form-label">Cancellation Details</label>
                                    </div>
                                    <div className="form-group col-lg-3">
                                        <input type="text" name="cancelby" placeholder="Canceled By" value={state.searchForm.cancelby} onChange={handleInputChange} className="form-control-custom" />
                                    </div>
                                    <div className="form-group col-lg-3">
                                        <input type="text" name="cancelboothnumber" placeholder="Booth #" value={state.searchForm.cancelboothnumber} onChange={handleInputChange} className="form-control-custom" />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-xs-12 col-md-12 col-lg-4">
                        <Card className="d2pCard">
                            <Card.Header><span>Packages</span></Card.Header>
                            <Card.Body>
                                <div><input type="checkbox" name="leadretrieval" checked={state.searchForm.leadretrieval} onChange={handleInputChange} />&nbsp;Lead Retrieval</div>
                                <div><input type="checkbox" name="internetlink" checked={state.searchForm.internetlink} onChange={handleInputChange} />&nbsp;Internet Link</div>
                                <div><input type="checkbox" name="qualifiedprospectlist" checked={state.searchForm.qualifiedprospectlist} onChange={handleInputChange} />&nbsp;Qualified Prospect List</div>

                                <div className="form-group col-lg-6">
                                    <label className="form-label">Total Cost</label>
                                    <input className="form-control-custom" type="text" name="totalcost" placeholder="" value={state.searchForm.totalcost} onChange={handleInputChange} />
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <Card className="d2pCard">
                            <Card.Header><span>Notes</span></Card.Header>
                            <Card.Body>
                                <textarea style={{ width: '100%', height: '200px' }} name="notes" placeholder="" value={state.searchForm.notes} onChange={handleInputChange} />
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </Drawer>
    );
}