import React, { useState, useEffect, useRef } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import Axios from '../../config/axios';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import Moment from 'moment';
import { useForm, Controller } from "react-hook-form";
import { Popover } from 'antd';
import Card from 'react-bootstrap/Card';

export default function ShowProgramDetailsSliderVO(props) {
    const [state, setState] = useState({
        showPane: false,
        dataLoaded: false,
        usersListData: [],
        selectedCustomerId: props.selectedCustomerId,
        selectedSalesOrderId: props.selectedShowCode,
        salesorder: {}
    });

    const { register, getValues, setValue, control, watch } = useForm();

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    useEffect(() => {
        LoadSalesOrderDetails();

    }, [props]);

    function LoadSalesOrderDetails() {
        let postdata = {};
        postdata.sales_order_id = props.selectedSalesOrderId;

        console.log(postdata);

        Axios.post(`/api/GetSalesOrderById`, postdata
        ).then(async response => {
            //console.log(response.data);

            let salesorder = response.data;

            setState({
                ...state,
                salesorder: salesorder,
                showPane: props.showPane,
                dataLoaded: true
            });

        }).catch(error => {
            console.log(error);
        });

    }

    function hidePane() {
        setState({
            ...state,
            showPane: false
        });

        setTimeout(() => {
            props.hidePane();
        }, 1000)
    }

    var currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    function calculateInvoiceCost() {
        if (state.salesorder.so?.agencycompany === null) {
            return currencyFormatter.format(state.salesorder.so.showprogramadprice);
        }
        else {
            return currencyFormatter.format(state.salesorder.so.showprogramadprice * .85);
        }
    }

    console.log(state);

    return (
        <SlidingPane
            className='some-custom-class w-65'
            overlayClassName='showCard'
            isOpen={state.showPane}
            title={'Sales Order Details for Show Program ' + state.salesorder.so?.line.issue}
            onRequestClose={hidePane}
            width={'90%'}
            closeIcon={<i className="fas fa-chevron-right float-left"></i>}
        >
            <div className="slide-pane-body scrollableDiv">
                <div>
                    {state.dataLoaded &&
                        <div className="row mb-3">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <Card className="d2pCard mb-3">
                                    <Card.Header><span>General Sales Order Information</span></Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="form-group col-lg-1">
                                                <label className="form-label">Sales Order</label>
                                                <label className="form-data-label">{state.salesorder.so?.header.sales_order_id}</label>
                                            </div>
                                            <div className="form-group col-lg-1">
                                                <label className="form-label">Status</label>
                                                <label className="form-data-label">{state.salesorder.so?.header.order_status}</label>
                                            </div>
                                            <div className="form-group col-lg-4">
                                                <label className="form-label">Advertiser Company</label>
                                                <label className="form-data-label">{state.salesorder.so?.advertisercompany}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Advertiser Contact</label>
                                                <label className="form-data-label">{state.salesorder.so?.advertisercontact}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Agency Company</label>
                                                <label className="form-data-label">{state.salesorder.so?.agencycompany === null ? 'N/A' : state.salesorder.so?.agencycompany}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Agency Contact</label>
                                                <label className="form-data-label">{state.salesorder.so?.agencycontact === null || state.salesorder.so?.agencycontact === null ? 'N/A' : state.salesorder.so?.agencycontact}</label>
                                            </div>

                                            <div className="form-group col-lg-4">
                                                <label className="form-label">Service Category</label>
                                                <label className="form-data-label">{state.salesorder.so?.header.service_category_desc}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Sales Person</label>
                                                <label className="form-data-label">{state.salesorder.so?.header.salesperson1}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Commission</label>
                                                <label className="form-data-label">{state.salesorder.so?.header.commission}%</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">PO</label>
                                                <label className="form-data-label">{state.salesorder.so?.header.po_number}</label>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">New Order</label>
                                                <label className="form-data-label">{state.salesorder.so?.header.new_order === true ? 'Yes' : 'No'}</label>
                                            </div>

                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Show Code</label>
                                            <label className="form-data-label">{state.salesorder.so?.show.showcode}</label>
                                        </div>
                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Show Abbrev</label>
                                            <label className="form-data-label">{state.salesorder.so?.show.show_abbrev}</label>
                                        </div>
                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Show Name</label>
                                            <label className="form-data-label">{state.salesorder.so?.show.name}</label>
                                        </div>
                                        <div className="form-group col-lg-2">
                                            <label className="form-label">Show Date</label>
                                            <label className="form-data-label">{Moment(dateWithNoTimezone(state.salesorder.so?.show.startdate)).format("L")}</label>
                                        </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-sm-16 col-md-6 col-lg-6">
                                <Card className="d2pCard mb-3">
                                    <Card.Header><span>Ad Details</span></Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="form-group col-lg-4"><label className="inline-form-label">Order Entry Date</label></div>
                                            <div className="form-group col-lg-8"><label className="form-data-label">{Moment(dateWithNoTimezone(state.salesorder.so?.header.entry_date)).format("L")}</label></div>

                                            <div className="form-group col-lg-4"><label className="inline-form-label">Ad Size</label></div>
                                            <div className="form-group col-lg-8"><label className="form-data-label">{state.salesorder.so?.line.size_unit}</label></div>

                                            <div className="form-group col-lg-4"><label className="inline-form-label">Color Process</label></div>
                                            <div className="form-group col-lg-8"><label className="form-data-label">{state.salesorder.so?.line.color_type}</label></div>

                                            <div className="form-group col-lg-4"><label className="inline-form-label">Material</label></div>
                                            <div className="form-group col-lg-8"><label className="form-data-label">{state.salesorder.so?.line.material}</label></div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-sm-16 col-md-6 col-lg-6">
                                <Card className="d2pCard mb-3">
                                    <Card.Header><span>Cost Details</span></Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="form-group col-lg-4"><label className="inline-form-label">Total Cost:</label></div>
                                            <div className="form-group col-lg-8"><label className="form-data-label">{currencyFormatter.format(state.salesorder.so.showprogramadprice)}</label></div>

                                            <div className="form-group col-lg-4"><label className="inline-form-label">Invoice Amount:</label></div>
                                            <div className="form-group col-lg-8"><label className="form-data-label">{calculateInvoiceCost()}</label></div>

                                            <div className="form-group col-lg-12"><label className="inline-form-label">&nbsp;</label></div>

                                            <div className="form-group col-lg-12"><label className="inline-form-label">&nbsp;</label></div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <Card className="d2pCard mb-3">
                                    <Card.Header><span>Additional Details</span></Card.Header>
                                    <Card.Body>
                                        <div className="row">
                                            <div className="form-group col-lg-6">
                                                <label className="form-label">Invoice Notes</label>
                                                <label className="form-data-label">{state.salesorder.so?.line.discount_explanation === null ? 'None specified' : state.salesorder.so?.line.discount_explanation}</label>
                                            </div>
                                            <div className="form-group col-lg-6">
                                                <label className="form-label">Production Instructions</label>
                                                <label className="form-data-label">{state.salesorder.so?.line.special_instructions === null ? 'None specified' : state.salesorder.so?.line.special_instructions}</label>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className="modal-footer">
                <div className="form-group col-12 padding-25-10">
                    <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                </div>
            </div>

        </SlidingPane>
    );
}