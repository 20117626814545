import React, { useEffect, useState, Fragment } from 'react';
import Axios from '../../config/axios';
import { toast, confirm } from '@rickylandino/react-messages';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import DatePicker from 'react-datepicker';
import Globals from '../../config/globals';
import { Drawer, Card, message, Table, Input, Empty, Spin, Alert } from 'antd';
import { FaCheck } from 'react-icons/fa';
import { TbUserCheck } from 'react-icons/tb';
import TaskDetailsSlider from './TaskDetailsSlider';
import { Button } from 'react-bootstrap';
import { dateWithNoTimezone } from '../Helpers/DateFormat';
import Moment from 'moment';
import { useNavigate, useLocation } from "react-router-dom";

export default function TaskManagerSlider(props) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    const navigate = useNavigate();

    const [state, setState] = useState({
        showPane: props.showPane,
        users: [],
        tasks: [],
        searchForm: {
            tasktypecalls: true,
            tasktypemeetings: true,
            tasktypetodos: true,
            taskpriorityhigh: true,
            taskprioritymedium: true,
            taskprioritylow: true,
            taskstatusactive: true,
            taskstatuscompleted: false,
            taskstatuscancelled: false,
            taskassignedto: "mytasks",
            taskspecificuser: "",
            taskdates: "all",
            taskspecificdate: new Date(),
            taskalarmonly: false
        },
        showTaskDetailsSlider: false,
        dataLoaded: false
    });

    const [tasks, setTasks] = useState([]);

    const [selectedRowKeys, setSelectedRowKeys] = useState(['']);

    useEffect(() => {
        setState({
            ...state,
            showPane: props.showPane
        });

        getTasks();

    }, [props]);

    function hidePane() {
        setState({
            ...state,
            showPane: false,
            showTaskDetailsSlider: false
        });

        setTimeout(() => { props.updateTableDisplay(tasks, '', 0); }, 1000);
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        // https://stackoverflow.com/questions/27105257/storing-an-object-in-state-of-a-react-component Kevin Danikowski answer
        setState({
            ...state,
            searchForm: { ...state.searchForm, [name]: value },
            showPane: props.showPane
        });

        console.log(state.showPane);
    }

    function handleClick(event) {
        console.log(event.target.id);

        event.preventDefault();

        switch (event.target.id) {

            case 'btnPrintReport':
                alert('Print Report');
                break;
            case 'btnAddNewTask':
                setState({
                    ...state,
                    showTaskDetailsSlider: true
                });

                break;
            case 'btnEditTask':
                this.edittask();
                break;
            case 'btnGetTasks':
                getTasks();
                break;
            default:
                break;
        }
    }

    function getTasks() {
        setState({
            ...state,
            showPane: props.showPane,
            showTaskDetailsSlider: false,
            dataLoaded: false
        });

        let postdata = {
            userid: userInfo.userId,
            jobshopdivision: userInfo.jobShopDivision,
            form: state.searchForm
        };

        const key = "loading";
        message.loading({
            content: 'Retrieving Tasks...',
            key,
            duration: 0,
        });

        Axios.post(`/api/GetFullTaskManager`, postdata
        ).then(response => {
            const tasks = response.data;

            setTasks(tasks);

            setState({
                ...state,
                tasks,
                dataLoaded: true,
                showPane: props.showPane,
                showTaskDetailsSlider: false
            });

            message.destroy(key);
        }).catch(error => {
            console.log(error);
        });
    }

    function getUsersList() {
        let postdata = {};

        Axios.post(`/api/GetTaskUserList`, postdata
        ).then(response => {
            const users = response.data;
            setState({
                ...state,
                users
            });
        }).catch(error => {
            console.log(error);
        });
    }

    //function GetUserActiveTasks() {
    //    let postdata = {};
    //    postdata.userid = userInfo.userId;

    //    Axios.post(`/api/GetUserActiveTasks`, postdata
    //    ).then(response => {
    //        const tasks = response.data;

    //        setTasks(tasks);

    //        console.log(tasks);

    //        //var selectedtask = "";
    //        //if (tasks && tasks.length > 0) selectedtask = tasks[0];

    //        setState({
    //            ...state,
    //            tasks,
    //            dataLoaded: true,
    //            showPane: props.showPane,
    //            showTaskDetailsSlider: false
    //        });

    //    }).catch(error => {
    //        console.log(error);
    //    });
    //}

    function dateFormatter(cell, row) {
        let date = cell;
        if (typeof cell !== 'object') {
            date = new Date(cell);
        }

        var month = date.getMonth() + 1;
        var day = date.getDate();
        var year = date.getFullYear();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';

        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;

        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        hours = hours < 10 ? '0' + hours : hours;

        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;

        return month + "/" + day + "/" + year + "  " + strTime;

    }

    const columns = [
        {
            dataIndex: 'Task_ID',
            title: 'Task_ID',
            key: 'Task_ID',
            hidden: true
        }, {
            dataIndex: 'company_id',
            title: 'company_id',
            key: 'company_id',
            hidden: true
        }, {
            dataIndex: 'Type',
            title: 'Task Type',
            key: 'Type',
            width: 120,
            sorter: (a, b) => a.Type.localeCompare(b.Type)
        }, {
            dataIndex: 'DateTime',
            title: 'Date / Time',
            key: 'DateTime',
            width: 200,
            sorter: (a, b) => new Date(a.DateTime) - new Date(b.DateTime),
            render: (DateTime) => {
                return (
                    <div>
                        {DateTime === null ? '' : dateFormatter(DateTime)}
                    </div>
                );
            }
        }, {
            dataIndex: 'ContactCompany',
            title: 'Contact/Company',
            key: 'ContactCompany',
            sorter: (a, b) => a.ContactCompany.localeCompare(b.ContactCompany)
        }, {
            dataIndex: 'Priority',
            title: 'Priority',
            key: 'Priority',
            width: 120,
            sorter: (a, b) => a.Priority.localeCompare(b.Priority)
        }, {
            dataIndex: 'Prospect',
            title: 'Prospect',
            key: 'Prospect',
            width: 120,
            align: 'center',
            sorter: (a, b) => a.Prospect - b.Prospect,
            render: (Prospect) => {
                return (
                    <div>
                        {Prospect ? <div><FaCheck className="fa-1x text-icon" /></div> : <div></div>}
                    </div>
                );
            }
        }, {
            title: 'Jump to Customer',
            key: 'action',
            width: 120,
            render: (record) => (
                <div id="jumpTo" className="hover"><TbUserCheck id="jumpTo" className="fa-1x text-icon" onClick={(e) => handleJumpTo(e, record)} /></div>
            ),
            align: 'center'
        }, {
            title: 'Task Details',
            key: 'action',
            width: 120,
            render: (record) => (
                <span className="hover" onClick={(e) => handleTaskDetails(e, record)}><i id="taskDetails" className="fas fa-edit fa-1x ml-3 text-center hover"></i></span>
            ),
            align: 'center'
        }
    ].filter(item => !item.hidden);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        columnWidth: 0, // Set the width to 0
        renderCell: () => "", // Render nothing inside
    };

    function handleAddNewTask() {
        setState({
            ...state,
            Task_ID: 0,
            showTaskDetailsSlider: true,
            showTaskManagerSlider: false
        })
    }

    function handleTaskDetails(e, record) {
        setState({
            ...state,
            Task_ID: record.Task_ID,
            //selectedIdx: rowIndex,
            selectedTaskId: record.Task_ID,
            showTaskDetailsSlider: true,
            showTaskManagerSlider: false
        })
    }

    function handleJumpTo(e, record) {
        navigate("/customerview", { state: { selectedCustomerId: record.company_id, callingComponent: 'MyActiveTasks' } });
    }

    async function updateTableDisplay(tasks, taskAction, idxToDirectTo = null) {
        await setState({ ...state, dataLoaded: false });

        if (taskAction !== 'add') {
            let refreshedtasks = [...tasks];

            setTasks(refreshedtasks);

            setState({
                ...state,
                dataLoaded: true,
                showTaskDetailsSlider: false,
                showTaskManagerSlider: false
            });
        }
        else {
            //Reload tasks from DB
            getTasks();
        }
    }

    return (
        <>
            <SlidingPane
                className='some-custom-class w-65'
                overlayClassName='showCard'
                isOpen={state.showPane}
                title='Task Manager'
                onRequestClose={hidePane}
                closeIcon={<i className="fas fa-chevron-right float-left"></i>}
            >
                <div className="slide-pane-body scrollableDiv">
                    <div>
                        {!state.loading &&
                            <Fragment>
                                <form className="">
                                    <div className="row">
                                        <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2" style={{ "overflow": "hidden" }}>
                                            <Card title="Task Type" className="d2p-taskmgr-card">
                                            <div><input type="checkbox" name="tasktypecalls" checked={state.searchForm.tasktypecalls} onChange={handleInputChange} />
                                                    &nbsp;Calls</div>
                                                <div><input type="checkbox" name="tasktypemeetings" checked={state.searchForm.tasktypemeetings} onChange={handleInputChange} />
                                                    &nbsp;Meetings</div>
                                                <div><input type="checkbox" name="tasktypetodos" checked={state.searchForm.tasktypetodos} onChange={handleInputChange} />
                                                    &nbsp;To-Do's</div>
                                                <br />
                                                <div><input type="checkbox" name="taskalarmonly" checked={state.searchForm.taskalarmonly} onChange={handleInputChange} />
                                                    &nbsp;Active Alarms Only</div>
                                            </Card>
                                        </div>
                                        <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2" style={{ "overflow": "hidden" }}>
                                            <Card title="Task Priority" className="d2p-taskmgr-card">
                                                <div><input type="checkbox" name="taskpriorityhigh" checked={state.searchForm.taskpriorityhigh} onChange={handleInputChange} />
                                                    &nbsp;High</div>
                                                <div><input type="checkbox" name="taskprioritymedium" checked={state.searchForm.taskprioritymedium} onChange={handleInputChange} />
                                                    &nbsp;Medium</div>
                                                <div><input type="checkbox" name="taskprioritylow" checked={state.searchForm.taskprioritylow} onChange={handleInputChange} />
                                                    &nbsp;Low</div>
                                            </Card>
                                        </div>
                                        <div className="col-xs-6 col-sm-2 col-md-2 col-lg-2" style={{ "overflow": "hidden" }}>
                                            <Card title="Task Status" className="d2p-taskmgr-card">
                                                <div><input type="checkbox" name="taskstatusactive" checked={state.searchForm.taskstatusactive} onChange={handleInputChange} />
                                                    &nbsp;Active</div>
                                                <div><input type="checkbox" name="taskstatuscompleted" checked={state.searchForm.taskstatuscompleted} onChange={handleInputChange} />
                                                    &nbsp;Completed</div>
                                                <div><input type="checkbox" name="taskstatuscancelled" checked={state.searchForm.taskstatuscancelled} onChange={handleInputChange} />
                                                    &nbsp;Cancelled</div>

                                            </Card>
                                        </div>
                                        <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3" style={{ "overflow": "hidden" }}>
                                            <Card title="Task Assigned To" className="d2p-taskmgr-card">
                                                <div><input type="radio" name="taskassignedto" value="mytasks" checked={state.searchForm.taskassignedto === 'mytasks'} onChange={handleInputChange} />
                                                    &nbsp;My Tasks</div>
                                                <div><input type="radio" name="taskassignedto" value="everyonestasks" checked={state.searchForm.taskassignedto === 'everyonestasks'} onChange={handleInputChange} disabled={userInfo.userType == 'admin' ? false : true} />
                                                    &nbsp;Everyone's Tasks</div>
                                                <div><input type="radio" name="taskassignedto" value="specificuserstasks" checked={state.searchForm.taskassignedto === 'specificuserstasks'} onChange={handleInputChange} disabled={userInfo.userType == 'admin' ? false : true} />
                                                    &nbsp;Specific User's Tasks
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <select id="ddUsers" name="taskspecificuser" value={state.searchForm.taskspecificuser} onChange={handleInputChange} disabled={state.searchForm.taskassignedto !== 'specificuserstasks'} disabled={userInfo.userType == 'admin' ? false : true} >
                                                        <option>Select User</option>
                                                        {/*{state.users.map((user) => <option key={user.id} value={user.id}>{user.id}</option>)}*/}
                                                    </select>

                                                </div>
                                            </Card>
                                        </div>
                                        <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3">
                                            <Card title="Task Dates" className="d2p-taskmgr-card">
                                                <div><input type="radio" name="taskdates" value="all" checked={state.searchForm.taskdates === 'all'} onChange={handleInputChange} />
                                                    &nbsp;All</div>
                                                <div><input type="radio" name="taskdates" value="todayandpast" checked={state.searchForm.taskdates === 'todayandpast'} onChange={handleInputChange} />
                                                    &nbsp;Today and Past</div>
                                                <div><input type="radio" name="taskdates" value="todayandfuture" checked={state.searchForm.taskdates === 'todayandfuture'} onChange={handleInputChange} />
                                                    &nbsp;Today and Future</div>
                                                <div><input type="radio" name="taskdates" value="past" checked={state.searchForm.taskdates === 'past'} onChange={handleInputChange} />
                                                    &nbsp;Past</div>
                                                <div><input type="radio" name="taskdates" value="specificdate" checked={state.searchForm.taskdates === 'specificdate'} onChange={handleInputChange} />
                                                    &nbsp;Specific Date&nbsp;
                                                    <DatePicker selected={new Date(state.searchForm.taskspecificdate)} onChange={(date) => setState({ ...state, searchForm: { ...state.searchForm, taskspecificdate: date } })} disabled={state.searchForm.taskdates !== 'specificdate'} />
                                                </div>
                                            </Card>

                                        </div>
                                    </div>
                                
                                    <div className="row mt-3">
                                        <div className="col-lg-12">
                                            <Button id="btnGetTasks" className="btn btn-submit me-3" onClick={handleClick}>Get Tasks</Button>
                                            <Button id="btnAddNewTask" className="btn btn-submit me-3" onClick={handleAddNewTask}>Add New Task</Button>
                                        </div>

                                        <p></p>
                                        <hr style={{ width: '80%', textAlign: "center"}} />
                                        <p></p>
                                    </div>
                                </form>
                                <div>
                                    <Spin spinning={!state.dataLoaded}>
                                        <div style={!state.dataLoaded ? {} : { display: 'none' }}>
                                            <Alert
                                                message="Loading Tasks"
                                                description="Please stand by while we retrieve tasks for this customer"
                                                type="info"
                                            />
                                        </div>
                                        {state.dataLoaded &&
                                            <Fragment>
                                                {tasks.length === 0 ?
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                                        <span>
                                                            No Active {userInfo.jobShopDivision} Tasks Found
                                                        </span>
                                                    } />
                                                    :
                                                    <Table className="custom-ant-selection"
                                                        rowKey={item => item.Task_ID}
                                                        rowSelection={rowSelection}
                                                        hideSelectionColumn={true}
                                                        bordered
                                                        dataSource={tasks}
                                                        columns={columns}
                                                        rowClassName={(record, index) => {
                                                            if (Moment(record.DateTime) < Moment(new Date())) {
                                                                return "text-danger";
                                                            }
                                                            else {
                                                                return "";
                                                            }
                                                        }}
                                                        pagination={{
                                                            defaultPageSize: 10,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ['10', '25', '50', '100'],
                                                            showTotal: (total, range) => (
                                                                <span className="color-dark-blue" style={{ left: 0, position: "absolute", fontSize: "large", fontWeight: "bold" }}>
                                                                    Showing {range[0]}-{range[1]} of {total}
                                                                </span>
                                                            )
                                                        }}
                                                        onRow={(record) => {
                                                            return {
                                                                onClick: () => {
                                                                    let selRows = [record.Task_ID];
                                                                    setSelectedRowKeys([...selRows]);
                                                                },
                                                                onDoubleClick: (e) => {
                                                                    handleTaskDetails(e, record);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                }
                                            </Fragment>
                                        }
                                    </Spin>
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>

                <div className="modal-footer">
                    <div className="form-group col-12 padding-25-10">
                        {/*<span><button className="btn btn-submit btn-sm" onClick={onApplyChanges}>Apply Changes</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>*/}
                        {/*<span style={getValues().formFields?.mrRequest.requestStatus === 'Completed' ? { display: 'none' } : {}}><button className="btn btn-submit btn-sm" onClick={openCompleteRequestModal}>Mark Request Complete</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>*/}
                        {/*<span><button className="btn btn-submit btn-sm" onClick={onPrintRequestPDFClick}>Print PDF</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>*/}
                        {/*<span style={getValues().formFields?.mrRequest.requestStatus === 'Pending' ? { display: 'none' } : {}}><button className="btn btn-submit btn-sm" onClick={onResendEmailNotificationsClick}>Resend Email Notifications</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>*/}
                        {/*<span><button className="btn btn-submit btn-sm" onClick={onHistoryClick}>History</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>*/}
                        <button className="btn btn-outline-primary margin-left-15 float-right" onClick={hidePane}>Close</button>
                    </div>
                </div>

            </SlidingPane>
            {state.showTaskDetailsSlider &&
                <TaskDetailsSlider showPane={state.showTaskDetailsSlider} Task_ID={state.Task_ID} updateTableDisplay={updateTableDisplay}
                    selectedTaskId={state.selectedTaskId} tasks={tasks} selectedIdx={state.selectedIdx} hidePane={() => setState({ ...state, showTaskDetailsSlider: false })}
                    parent='TaskManagerSlider' selectedCustomerId={null} selectedCustomerName={null} />
            }
        </>
    );
}