export function convertHtmlToText(html) {

    var reg = /\<body[^>]*\>([^]*)\<\/body/m;
    var bodyText = html;
    if (html?.match(reg)) {
        bodyText = html.match(reg)[1];
    }

    bodyText = bodyText?.replace(/&nbsp;/g, ' ');

    bodyText = bodyText?.replace('.</p>', '. ');
    bodyText = bodyText?.replace('. </p>', '. ');
    bodyText = bodyText?.replace(',</p>', ', ');
    bodyText = bodyText?.replace(', </p>', ', ');

    bodyText = bodyText?.replace('</p>', '. ');

    bodyText = bodyText?.replace(/<[^>]*>/g, ' ');

    bodyText = bodyText?.replace(/\s+/g, ' ');

    return bodyText.trim();
}

export function isHTML(str) {
    var doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}

//export const disableSliderInputFields = () => {
//    document.querySelectorAll('.slide-pane input').forEach(input => {
//        input.disabled = 'disabled'
//        console.log(input);
//    })

//    document.querySelectorAll('.slide-pane select').forEach(input => {
//        //console.log(input);
//        input.disabled = 'disabled'
//    })

//    //document.querySelectorAll('.slide-pane Select').forEach(input => {
//    //    console.log(input);
//    //    input.disabled = 'disabled'
//    //})

//    document.querySelectorAll('.ant-select-selector input').forEach(input => {
//        input.disabled = 'disabled';
//        console.log(input);
//    })
//}

//export const enableInputFields = () => {
//    document.querySelectorAll('.slide-pane input').forEach(input => {
//        input.disabled = 'disabled'
//    })
//}